import { observable, action } from 'mobx'
import BaseStore from './baseStore'
import { getStore } from '.'

class HeaderStore extends BaseStore {
  constructor() {
    super()
  }

  @observable
  longTouchStatus = false

  @observable
  menuDrawerVisible = false

  @observable
  userDrawerVisible = false

  @observable
  roomListModalVisible = false

  @observable
  tableListModalVisible = false

  @action
  updateRoomListModalVisible(val: boolean = true, isClear: boolean = true) {
    this.roomListModalVisible = val

    if (!val && isClear) {
      const { songStore } = getStore()
      songStore.currentChooseSongData = {}
    }
  }

  @action
  updateTableListModalVisible(val: boolean = true) {
    this.tableListModalVisible = val
  }

  @action
  updateMenuDrawerVisible(val: boolean = true) {
    this.menuDrawerVisible = val

    if (val && this.userDrawerVisible) {
      this.updateUserDrawerVisible(false)
    }
  }

  @action
  updateUserDrawerVisible(val: boolean = true) {
    this.userDrawerVisible = val

    if (!val && this.longTouchStatus) {
      this.updateLongTouchStatus(false)
    }

    if (val && this.menuDrawerVisible) {
      this.updateMenuDrawerVisible(false)
    }
  }

  @action
  updateLongTouchStatus(val: boolean = true) {
    this.longTouchStatus = val
  }
}

export default HeaderStore
