export default {
  "com.title.home": "RUMAH",
  "com.title.chosen": "DIPILIH",
  "com.title.more": "LEBIH BANYAK",
  "com.title.feedback": "UMPAN BALIK", // new
  "com.title.set": "SIAPKAN",
  "com.title.login": "Log in", // new

  "com.user.connect": "KONEKSI",
  "com.user.disconnect": "TIDAK KONEKSI",
  "com.user.room-list": "KAMAR PRIBADI",
  "com.user.table-list": "DAFTAR TABEL",
  "com.user.call-service": "LAYANAN PANGGILAN",
  "com.user.cancel-service": "BATALKAN LAYANAN",
  "com.user.logout": "退出登录",

  "com.song.name": "NAMA",
  "com.song.number": "NOMOR",
  "com.song.language": "BAHASA",
  "com.song.song": "NAMA LAGU",
  "com.song.singer": "PENYANYI",
  "com.song.up-tone": "NAIK",
  "com.song.down-tone": "JATUHKAN",
  "com.song.accompaniment": "DUKUNGAN",
  "com.song.original": "PENYANYI ASLI",
  "com.song.insert": "PRIORITAS",
  "com.song.delete": "HAPUS",
  "com.song.add-collect": "TAMBAHKAN KOLEKSI",
  "com.song.select": "LAGU",
  "com.song.ranking": "PERINGKAT",
  "com.song.new-song": "LAGU BARU",
  "com.song.category": "KLASIFIKASI",
  "com.song.dance": "MUSIK DANSA",
  "com.song.hot": "POPULER",
  "com.song.collect": "KOLEKSI",
  "com.song.history": "History",
  "com.song.all": "SEMUA",
  "com.song.play-control": "KONTROL SIARAN",
  "com.song.next": "POTONG LAGU",
  "com.song.play": "MAIN",
  "com.song.pause": "TANGGUHKAN",
  "com.song.restart": "PUTAR ULANG",
  "com.song.microphone": "MIKROFON",
  "com.song.music": "MUSIK",
  "com.song.tone": "NADA",
  "com.song.spell": "EJAAN SEDERHANA",
  "com.song.download": "UNDUH",
  "com.song.downloading": "MENGUNDUH",
  "com.song.edit": "UBAH",
  "com.song.playing": "SEKARANG BERMAIN",
  "com.song.new-ytb": "YouTube",
  
  "page.login.login-in": "Masuk",
  "page.login.hall": "Hall",
  "page.login.room": "Room",

  "page.chosen.order": "URUTAN",
  "page.chosen.myself": "MEJA SAYA",

  "page.setting.collect-code": "KODE KOLEKSI",
  "page.setting.version": "VERSI",

  "page.singer.cn-man": "LAKI-LAKI DARATAN",
  "page.singer.cn-woman": "PEREMPUAN DARATAN",
  "page.singer.hk-man": "LAKI-LAKI HONG KONG",
  "page.singer.hk-woman": "PEREMPUAN HONG KONG",
  "page.singer.tw-man": "LAKI-LAKI TAIWAN",
  "page.singer.tw-woman": "PEREMPUAN TAIWAN",
  "page.singer.oversea-man": "LAKI-LAKI LUAR NEGERI",
  "page.singer.oversea-woman": "PEREMPUAN LUAR NEGERI",
  "page.singer.other-man": "LAKI-LAKI LAINNYA",
  "page.singer.other-woman": "PEREMPUAN LAINNYA",
  "page.singer.band": "KOMBINASI",

  "tips.loading": "MEMUAT",

  "tips.success.handle": "OPERASI BERHASIL",
  "tips.success.collect": "KEBERHASILAN KOLEKSI",
  "tips.success.clear-storage": "BERHASIL DIHAPUS,SEGARKAN HALAMAN",
  "tips.success.connect": "BERHASIL TERHUBUNG",
  "tips.success.set": "PENYIAPAN BERHASIL",

  "tips.warning.collect": "收藏已存在", // new
  "tips.warning.set-collect-code": "SILAKAN TENTUKAN KODE KOLEKSI ANDA TERLEBIH DAHULU",
  "tips.warning.control-auth": "HANYA PUTAR LAGU SESUAI PERMINTAAN ANDA",
  "tips.warning.input-password": "SILAKAN MASUKKAN KATA SANDI",
  "tips.warning.input-feedback": "SILAKAN MASUKKAN UMPAN BALIK",
  "tips.warning.input-connect-code": "SILAKAN MASUKKAN KODE KONEKSI 6 DIGIT",
  "tips.warning.table-closed": "MEJA DITUTUP",
  "tips.warning.room-closed": "KAMAR PRIBADI DITUTUP",
  "tips.warning.reconnect": "GAGAL MASUK,HARAP SAMBUNGKAN KEMBALI",
  "tips.warning.room-password-error": "包房密码错误", // new
  "tips.warning.link-again": "请先连接包房或桌台", // new
  "tips.warning.client-error": "客户端已经断开", // new
  "tips.warning.no-available": "No-available", // new
  "tips.no-data": "No Result!", // new

  "tips.error.network-fail": "JARINGANNYA BERJALAN BURUK",
  "tips.error.page-lost": "HALAMANNYA HILANG～",
  "tips.error.connect-code": "KESALAHAN KODE KONEKSI",
  "tips.error.token": "KESALAHAN TOKEN",
  "tips.error.client-network": "PENGECUALIAN JARINGAN",
  "tips.error.logic": "KESALAHAN LOGIKA",
  "tips.error.query-params": "KESALAHAN PARAMETER",
  "tips.error.query": "KESALAHAN PERMINTAAN",
  "tips.error.server": "KESALAHAN SERVER",

  "btn.retry": "COBA LAGI",
  "btn.confirm": "OKE",
  "btn.cancel": "BATALKAN",
  "btn.submit": "KIRIM",
  "btn.back-home": "KEMBALI KE RUMAH",
  "btn.refresh": "Update", // new
  "btn.clear-data": "Clear Cache", // new

  "const.lang.jtzw": "MANDARIN SEDERHANA",
  "const.lang.gy": "MANDARIN",
  "const.lang.ftzw": "CINA TRADISIONAL",
  "const.lang.yy": "KANTON",
  "const.lang.en": "BAHASA INGGRIS",
  "const.lang.ydnxyy": "ORANG INDONESIA",
  "const.lang.mlxyy": "MELAYU",
  "const.lang.mny": "MINNAN",
  "const.lang.ry": "ORANG JEPANG",
  "const.lang.hy": "KOREA",
  "const.lang.yny": "ORANG VIETNAM",
  "const.lang.jpzy": "KAMBOJA",
  "const.lang.fy": "PRANCIS",
  "const.lang.ty": "THAILAND",
  "const.lang.ydy": "HINDI",
  "const.lang.flby": "ORANG FILIPINA",
  "const.lang.xbyy": "SPANYOL",
  "const.lang.ey": "RUSIA",
  "const.lang.mdy": "BURMA",
  "const.lang.tmr": "TAMIL",
  "const.lang.yday": "HINDI",
  "const.lang.cz": "CHAOZHOU",
  "const.lang.other": "BAHASA LAIN",

  "const.type.img_fenlei01": "PADUAN SUARA",
  "const.type.img_fenlei02": "LAGU REVOLUSIONER",
  "const.type.img_fenlei03": "LAGU ANAK-ANAK",
  "const.type.img_fenlei04": "NOSTALGIA",
  "const.type.img_fenlei05": "MERIAH",
  "const.type.img_fenlei06": "OPERA KANTON",
  "const.type.img_fenlei07": "OPERA PEKING",
  "const.type.img_fenlei08": "LAJU ULANG TAHUN",
  "const.type.img_fenlei09": "YUE OPERA",
  "const.type.img_fenlei10": "OPERA HUANGMEI",
  "const.type.img_fenlei11": "DRAMA LAINNYA",
  "const.type.img_fenlei12": "DRAMA CHAO",
  "const.type.img_fenlei13": "MUSIK RINGAN",
  "const.type.img_fenlei14": "SHAANXI QINCAO",
  "const.type.img_fenlei15": "DUOZHUAN TIMUR LAUT",
  "const.type.img_fenlei16": "YU OPERA",
  "const.type.img_fenlei17": "LAGU-LAGU TIBET",
  "const.type.img_fenlei18": "LAGU XINJIANG",
  "const.type.img_fenlei19": "MUSIK HIFI",

  "const.type.img_dance02": "TUSUK SATE",
  "const.type.img_dance03": "DISKO",
  "const.type.img_dance04": "ADIL",
  "const.type.img_dance05": "PRUSIA",
  "const.type.img_dance06": "TANGO",
  "const.type.img_dance07": "WALTZ",
  "const.type.img_dance08": "GTIBA",
  "const.type.img_dance09": "KOCOK PERLAHAN",
  "const.type.img_dance10": "TERIAN SOSIAL",
  "const.type.img_dance11": "KLUB MALAM",
  "const.type.img_dance12": "MUSIK DANSA ELEKTRONIK",
  "const.type.img_dance13": "VITALITAS TANPA BATAS",
  "const.type.img_dance14": "ELEKTRONIK SEGAR",
  "const.type.img_dance15": "MANDARIN",

  "const.type.img_paihang": "TOTAL PERINGKAT",
  "const.type.img_chinese": "DAFTAR BAHASA NASIONAL",
  "const.type.img_yueyu": "DAFTAR BAHASA KANTON",
  "const.type.img_minnanyu": "DIALEK MINNAN",
  "const.type.img_english": "DAFTAR BAHASA INGGRIS",
  "const.type.img_ktv": "DAFTAR EMAS KTV",
  "const.type.img_hot_song": "POPULER",
  "const.type.img_rock": "DAFTAR ROCK",

  "const.type.hot_zgyyggp": "IDOL HITS",
  "const.type.hot_jdylc": "EVERLASTING CLASSIC",
  "const.type.hot_wxgyj": "UNLIMITED SONG SEASON",
  "const.type.hot_zgxsd": "LET'S SING KID'S",
  "const.type.hot_mrzz": "THE COMING ONE",
  "const.type.hot_zgxsc": "THE RAP OF CHINA",
  "const.type.hot_ayzc": "PHANTACITY",
  "const.type.hot_zydwm": "BEST US",
  "const.type.hot_srrx": "SUPER VOCAL",
  "const.type.hot_gs": "I AM A SINGER",
  "const.type.hot_zghsy": "SING!CHINA",
  "const.type.hot_wwgk": "MAD FOR MUSIC",
  "const.type.hot_zgzqy": "TEH X FACTOR",
  "const.type.hot_zgmzs": "CHINESE IDOL",
  "const.type.hot_zmhs": "DUETS",
  "const.type.hot_qnxz": "CELEBRITY BATTLE",
  "const.type.hot_zghgq": "SING MY SONG",
  "const.type.hot_zgzx": "CHINA STAR",
  "const.type.hot_wxhnc": "COME SING WITH ME",
  "const.type.hot_kjgw": "KIGN CROSS",
  "const.type.hot_ycgs": "TELENTED SINGER",
  "const.type.hot_gsyx": "HEROS OF EARTH",
  "const.type.hot_zgxgs": "SING!CHINA",
  "const.type.hot_mmgw": "MASKED KING",
  "const.type.hot_tlzz": "THE SOUND OF WARS",
  "const.type.hot_mmcj": "MASKED SINGER",
  "const.type.hot_mxdsy": "SOUND OF MY DREAM",
  "const.type.hot_wlyyh": "THE RECYCLING CONCERT",
  "const.type.hot_lhlwdg": "WOW! MY SONG",
  "const.type.hot_jql": "GOLDEN MELODY",
  "const.type.hot_zgyxh": "THE RAP OF CHINA",
}
