import React, { useState, useEffect } from 'react';
import { Modal, List, Button, Toast, WhiteSpace } from 'antd-mobile';

import './index.scss'
import { updateLoginRoomModalStatus, userConnect, getLoginRoomModalTime } from '@/utils/userHelper';
import { roomListInfo } from '@/api/api';
import AppList from '../AppList';
import { useStore } from '@/store';
import { observer } from 'mobx-react-lite';
import AppInput from '../AppInput';
import { useFormatMessage } from '@/locales';

interface RoomListModalProps {

}

const RoomListModal: React.FC<RoomListModalProps> = (props) => {
  const [loginRoomStatus, setLoginRoomStatus] = useState(false)
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)

  const { headerStore, songStore } = useStore()
  const intlMsg = useFormatMessage()

  useEffect(() => {
    if (!headerStore.roomListModalVisible) return

    const fn = async () => {
      const time = await getLoginRoomModalTime()

      if (time && ((Date.now() - time) / 1000 <= 86400)) {
        setLoginRoomStatus(true)
      } else {
        setLoginRoomStatus(false)
      }
    }

    fn()
  }, [headerStore.roomListModalVisible])

  const handleQueryData = async () => {
    const data = await roomListInfo()
    return data.list
  }

  const handleChange = (e: React.BaseSyntheticEvent) => {
    const value = e.target.value
    setValue(value)
  }

  const handleSubmit = async () => {
    if (!value) {
      Toast.fail(intlMsg['tips.warning.input-password'])
      return
    }
    setLoading(true)

    updateLoginRoomModalStatus(value).then(() => {
      setLoginRoomStatus(true)
    }).finally(() => {
      setLoading(false)
    })
  }

  const handleClickRoom = async (data: any) => {
    const params = {
      machineName: data.room_name,
      name: data.room_name,
      token: data.token,
      type: "room",
    }

    if (songStore.currentChooseSongData.songNumber) {
      songStore.currentChooseSongData.roomName = data.room_name
      songStore.currentChooseSongData.linked = data.linked
      songStore.updateChooseSongModalVisible()
    } else {
      userConnect('', params)
    }

    headerStore.updateRoomListModalVisible(false, false)
  }

  const Row = (props: any) => {
    const { source } = props
    return (
      <List.Item onClick={() => handleClickRoom(source)}>
        {source.room_name}
      </List.Item>
    )
  }

  return (
    <Modal closable popup visible={headerStore.roomListModalVisible} onClose={() => headerStore.updateRoomListModalVisible(false)} animationType="slide-up" className="room-list-wrap">
        {
          loginRoomStatus ? (
              <AppList row={Row} request={handleQueryData} />
          ) : (
            <div className="password-wrap">
              <AppInput value={value} onChange={handleChange} placeholder={intlMsg['tips.warning.input-password']}/>
              <WhiteSpace/>
              <Button className="submit-button" type="primary" onClick={handleSubmit} loading={loading} disabled={loading}>{intlMsg['btn.confirm']}</Button>
            </div>
          )
        }
    </Modal>
  );
};

export default observer(RoomListModal);
