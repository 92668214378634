import lazyComponent from './lazyComponent'
import history from './history'
import { RouteAttrsType, RoutePropsType } from './PropsType'
import { generatePath } from 'react-router'
import querystring from 'querystring'

export default class RouteExtends {
  attrs: RouteAttrsType

  constructor({
    loader,
    sensitive = true,
    exact = true,
    strict = true,
    auth = false,
    name = '',
    title = '',
    ...rest
  }: RoutePropsType) {
    const component = lazyComponent({
      loader,
    })

    this.attrs = {
      component,
      sensitive,
      exact,
      strict,
      auth,
      name,
      title,
      ...rest,
    }
  }

  protected formatPath(params = {}, query: any = {}) {
    let path = generatePath(this.attrs.path as string, params)

    query['t'] = new Date().getTime()
    path += `?${querystring.encode(query)}`

    return path
  }

  pushSelf(params = {}, query = {}) {
    return history.push(this.formatPath(params, query))
  }

  replaceSelf(params = {}, query = {}) {
    return history.replace(this.formatPath(params, query))
  }
}
