// import React from 'react'
// import { IntlProvider, FormattedMessage, MessageDescriptor, useIntl, createIntlCache, createIntl } from 'react-intl';
import { useStore, getStore } from '@/store';
// import { observer } from 'mobx-react-lite';

import zh_CN from './zh-CN';
// import zh_TW from './zh-TW';
import en_US from './en-US';
import id_ID from './id-ID';
import ms_MY from './ms-MY';

// interface IntlMessageProps extends MessageDescriptor {}

export const messagesMap: any = {
  zh_CN,
  // zh_TW,
  en_US,
  id_ID,
  ms_MY
}

// export const Intl = observer(({ children }: any) => {
//   const { userStore } = useStore()

//   return (
//     <IntlProvider locale={navigator.language} key={userStore.language} messages={messagesMap[userStore.language]}>
//       { children }
//     </IntlProvider>
//   )
// })

// export const FormatMessage: React.FC<IntlMessageProps> = (props) => {
//   const { children, ...rest } = props
//   return <FormattedMessage {...rest} />
// }

// export const useFormatMessage = (params?: MessageDescriptor) => {
//   const intl = useIntl()
//   const { userStore } = useStore()

//   if (params) {
//     return intl.formatMessage(params)
//   }

//   if (!Object.keys(userStore.intlMessages).length) {
//     const msg: any = {}

//     Object.keys(messagesMap[userStore.language]).forEach((key) => {
//       msg[key] = intl.formatMessage({ id: key })
//     })

//     userStore.intlMessages = msg
//   }

//   return userStore.intlMessages
// }

// export const getFormatMessage = () => {
//   const { userStore } = getStore()

//   if (!Object.keys(userStore.intlMessages).length) {
//     const msg: any = {}
//     const cache = createIntlCache()

//     const intl = createIntl({
//       locale: navigator.language,
//       messages: messagesMap[userStore.language]
//     }, cache)

//     Object.keys(messagesMap[userStore.language]).forEach((key) => {
//       msg[key] = intl.formatMessage({ id: key })
//     })

//     userStore.intlMessages = msg
//   }

//   return userStore.intlMessages
// }

export const useFormatMessage = () => {
  return getFormatMessage()
}

export const getFormatMessage = () => {
  const { userStore } = getStore()

  if (!Object.keys(userStore.intlMessages).length) {
    const msg: any = {}

    Object.keys(messagesMap[userStore.language]).forEach((key) => {
      msg[key] = messagesMap[userStore.language][key]
    })

    userStore.intlMessages = msg
  }

  return userStore.intlMessages
}
