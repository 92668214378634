import React from 'react'
import { useLocalStore } from 'mobx-react-lite'

import UserStore from './userStore'
import HeaderStore from './headerStore';
import SongStore from './songStore';

interface IStoreContext {
  userStore: UserStore
  headerStore: HeaderStore,
  songStore: SongStore,
}

const stores = {
  userStore: new UserStore(),
  headerStore: new HeaderStore(),
  songStore: new SongStore(),
}

const storeContext = React.createContext<IStoreContext | null>(null)

export const StoreProvider = ({ children }: any) => {
  const store: any = useLocalStore(() => stores)
  return <storeContext.Provider value={store}>{children}</storeContext.Provider>
}

export const useStore = () => {
  const store = React.useContext(storeContext)
  if (!store) {
    throw new Error('You have forgot to use StoreProvider, shame on you.')
  }
  return store
}

export const getStore = () => stores
