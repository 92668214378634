import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import './styles/app.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { StoreProvider } from '@/store'
// import { Intl } from '@/locales';
import { initUserStatus, autoUserConnect } from '@/utils/userHelper';
import { disableUserScalable, listenVirtualkeyboardAction, initVconsole } from './utils/utils'

import FastClick from 'fastclick'

FastClick.attach(document.body)
// initVconsole()

const initApp = async () => {
  await initUserStatus()
  await autoUserConnect()
}

Promise.all([

  disableUserScalable(),
  listenVirtualkeyboardAction(),
  initApp()

]).then(() => {
  ReactDOM.render(
    <StoreProvider>
      {/* <Intl> */}
        <App />
      {/* </Intl> */}
    </StoreProvider>,
    document.getElementById('root'),
  )
  // Test Version period of validity
  // if (new Date() > new Date('2020/02/15 00:00:00')) {
  //   (document.getElementById('root') as HTMLElement).innerHTML = ''
  // }
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
