import { Toast } from 'antd-mobile'
import appConfig from '@/config'
import { isAndroid, isChrome } from './platformHelper';

export const pxTransform = (size: string | number) => {
  const designWidth = +(process as any).env.REACT_APP_designWidth
  return (
    Math.ceil((((parseInt(`${size}`, 10) / 40) * 640) / designWidth) * 10000) /
      10000 +
    'rem'
  )
}

/**
 * 获取指定的url查询参数值
 * @param {string} pname 参数名称
 * @param {string} purl url地址，默认为当前url地址
 */
export function getParameterByName(pname: string, purl?: string): any {
  let name = pname
  let url = purl

  if (!url) url = window.location.href

  name = name.replace(/[[\]]/g, '\\$&')

  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)

  if (!results) return null
  if (!results[2]) return ''

  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function updateQueryStringParameter(
  uri: string,
  key: string,
  value: string,
) {
  var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
  var separator = uri.indexOf('?') !== -1 ? '&' : '?'
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2')
  } else {
    return uri + separator + key + '=' + value
  }
}

export function ToastFail(
  content = '',
  duration = 1,
  onClose = () => {},
  mask = false,
) {
  Toast.fail(content, duration, onClose, mask)
}

export function ToastSuccess(
  content = '',
  duration = 1,
  onClose = () => {},
  mask = false,
) {
  Toast.success(content, duration, onClose, mask)
}

export function disableUserScalable() {
  window.onload = function() {
    document.addEventListener('touchstart', function(event) {
      if (event.touches.length > 1) {
        event.preventDefault()
      }
    })
    let lastTouchEnd = 0
    document.addEventListener(
      'touchend',
      function(event) {
        const now = new Date().getTime()
        if (now - lastTouchEnd <= 300) {
          event.preventDefault()
        }
        lastTouchEnd = now
      },
      false,
    )
    document.addEventListener('gesturestart', function(event) {
      event.preventDefault()
    })
  }
}

export function listenVirtualkeyboardAction() {
  window.onload = function() {
    if (!isAndroid()) return
    if (isChrome()) return

    const originHeight =
      document.documentElement.clientHeight || document.body.clientHeight
    window.addEventListener(
      'resize',
      () => {
        const resizeHeight =
          document.documentElement.clientHeight || document.body.clientHeight
        if (resizeHeight < originHeight) {
          // 键盘弹起
          console.log('键盘弹起');
        } else {
          // 键盘收起
          console.log('键盘收起');
          const input: any = document.querySelector('.search-bar-wrap input')
          input && input.blur()
        }
      },
      false,
    )
  }
}

export function formatSecondsToMinutes(val: number) {
  const translationVal = (val: number) => (val < 10 ? `0${val}` : val)

  if (val < 60) {
    return `00:${translationVal(val)}`
  }

  const m = Math.floor(val / 60)
  const s = val % 60

  return `${translationVal(m)}:${translationVal(s)}`
}

export function getDefaultExport(module: any) {
  return module && module.__esModule ? module['default'] : module
}

export function initVconsole() {
  if (!appConfig.REACT_APP_debug) return
  import('vconsole').then(module => {
    const Vconsole = getDefaultExport(module)
    new Vconsole()
  })
}
