const config: any = {}

const boolReg = /^(true|false)$/

Object.keys(process.env).forEach(key => {
  const vlaue = (config[key] = process.env[key]) as string

  if (boolReg.test(vlaue)) {
    config[key] = vlaue !== 'false'
  }
})

export default config
