import React, { InputHTMLAttributes } from 'react';
import './index.scss'

const AppInput: React.FC<InputHTMLAttributes<any>> = (props) => {
  return (
    <div className="app-input-wrap">
      <div className="app-input-inner">
        <input {...props} />
      </div>
    </div>
  );
};

export default AppInput;
