import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { RouteWrap, getRoutesAttrs, history } from './router'
import RootView from '@/components/RootView';
import Page404 from './pages/404'

const routes = getRoutesAttrs()

const App: React.FC = () => {
  return (
    <Router history={history}>
      <RootView>
        <Switch>
          {routes.map((attrs, index) => (
            <RouteWrap key={index} {...attrs} />
          ))}
          <Route component={Page404} />
        </Switch>
      </RootView>
    </Router>
  )
}

export default App
