import img_paihang from '../assets/images/img_paihang.png';
import img_chinese from '../assets/images/img_chinese.png';
import img_yueyu from '../assets/images/img_yueyu.png';
import img_minnanyu from '../assets/images/img_minnanyu.png';
import img_english from '../assets/images/img_english.png';
import img_ktv from '../assets/images/img_ktv.png';
import img_hot_song from '../assets/images/img_hot_song.png';
import img_rock from '../assets/images/img_rock.png';

import img_all from '@/assets/images/img_all.png';
import hot_ayzc from '@/assets/images/hot_ayzc.png';
import hot_gs from '@/assets/images/hot_gs.png';
import hot_gsyx from '@/assets/images/hot_gsyx.png';
import hot_jdylc from '@/assets/images/hot_jdylc.png';
import hot_jql from '@/assets/images/hot_jql.png';
import hot_kjgw from '@/assets/images/hot_kjgw.png';
import hot_lhlwdg from '@/assets/images/hot_lhlwdg.png';
import hot_mmcj from '@/assets/images/hot_mmcj.png';
import hot_mmgw from '@/assets/images/hot_mmgw.png';
import hot_mrzz from '@/assets/images/hot_mrzz.png';
import hot_mxdsy from '@/assets/images/hot_mxdsy.png';
import hot_tlzz from '@/assets/images/hot_tlzz.png';
import hot_wlyyh from '@/assets/images/hot_wlyyh.png';
import hot_wwgk from '@/assets/images/hot_wwgk.png';
import hot_wxgyj from '@/assets/images/hot_wxgyj.png';
import hot_wxhnc from '@/assets/images/hot_wxhnc.png';
import hot_ycgs from '@/assets/images/hot_ycgs.png';
// import hot_ykts from '@/assets/images/hot_ykts.png';
import hot_zghgq from '@/assets/images/hot_zghgq.png';
import hot_zghsy from '@/assets/images/hot_zghsy.png';
import hot_zgmzs from '@/assets/images/hot_zgmzs.png';
import hot_zgxgs from '@/assets/images/hot_zgxgs.png';
import hot_zgxsc from '@/assets/images/hot_zgxsc.png';
import hot_zgxsd from '@/assets/images/hot_zgxsd.png';
import hot_zgyxh from '@/assets/images/hot_zgyxh.png';
import hot_zgyyggp from '@/assets/images/hot_zgyyggp.png';
import hot_zgzqy from '@/assets/images/hot_zgzqy.png';
import hot_zgzx from '@/assets/images/hot_zgzx.png';
import hot_zmhs from '@/assets/images/hot_zmhs.png';
import hot_zydwm from '@/assets/images/hot_zydwm.png';
import hot_srrx from '@/assets/images/hot_srrx.png';
import hot_qnxz from '@/assets/images/hot_qnxz.png';

import img_fenlei01 from '@/assets/images/img_fenlei01.png';
import img_fenlei02 from '@/assets/images/img_fenlei02.png';
import img_fenlei03 from '@/assets/images/img_fenlei03.png';
import img_fenlei04 from '@/assets/images/img_fenlei04.png';
import img_fenlei05 from '@/assets/images/img_fenlei05.png';
import img_fenlei06 from '@/assets/images/img_fenlei06.png';
import img_fenlei07 from '@/assets/images/img_fenlei07.png';
import img_fenlei08 from '@/assets/images/img_fenlei08.png';
import img_fenlei09 from '@/assets/images/img_fenlei09.png';
import img_fenlei10 from '@/assets/images/img_fenlei10.png';
import img_fenlei11 from '@/assets/images/img_fenlei11.png';
import img_fenlei12 from '@/assets/images/img_fenlei12.png';
import img_fenlei13 from '@/assets/images/img_fenlei13.png';
import img_fenlei14 from '@/assets/images/img_fenlei14.png';
import img_fenlei15 from '@/assets/images/img_fenlei15.png';
import img_fenlei16 from '@/assets/images/img_fenlei16.png';
import img_fenlei17 from '@/assets/images/img_fenlei17.png';
import img_fenlei18 from '@/assets/images/img_fenlei18.png';
import img_fenlei19 from '@/assets/images/img_fenlei19.png';

import img_dance02 from '@/assets/images/img_dance02.png';
import img_dance03 from '@/assets/images/img_dance03.png';
import img_dance04 from '@/assets/images/img_dance04.png';
import img_dance05 from '@/assets/images/img_dance05.png';
import img_dance06 from '@/assets/images/img_dance06.png';
import img_dance07 from '@/assets/images/img_dance07.png';
import img_dance08 from '@/assets/images/img_dance08.png';
import img_dance09 from '@/assets/images/img_dance09.png';
import img_dance10 from '@/assets/images/img_dance_10.png';
import img_dance11 from '@/assets/images/img_dance_11.png';
import img_dance12 from '@/assets/images/img_dance12.png';
import img_dance13 from '@/assets/images/img_dance13.png';
import img_dance14 from '@/assets/images/img_dance14.png';
import img_dance15 from '@/assets/images/img_dance15.png';
import { getFormatMessage } from '@/locales';

const intlMsg = getFormatMessage()

export const version = 'v0.0.1'
export const photoAssetUrlPrefix = 'http://apps.lasermusicsg.com/phone/photo/'
export const videoAssetUrlPrefix = 'http://apps.lasermusicsg.com/phone/video/'

export const langLabelMap = [
  intlMsg['const.lang.jtzw'],
  // intlMsg['const.lang.ftzw'],
  intlMsg['const.lang.en'],
  intlMsg['const.lang.ydnxyy'],
  intlMsg['const.lang.mlxyy']
]

export const langKeyMap = [
  'zh_CN',
  // 'zh_TW',
  'en_US',
  'id_ID',
  'ms_MY'
]

export const colors = [
   '#FFF68F',
   '#FFE1FF',
   '#F0FFF0',
   '#FAFAD2',
   '#FFCCCC',
   '#00FFFF',
   '#E0FFFF',
   '#99CC99',
   '#99CCFF',
   '#CC99CC',
   '#CCFFCC',
   '#FF6666',
   '#99CCFF'
]

// 因为后端语言索引从 1 开始，这里将索引 0 补充为其他语言
export const langTypes = [
  intlMsg['const.lang.other'],
  intlMsg['const.lang.gy'],
  intlMsg['const.lang.yy'],
  intlMsg['const.lang.mny'],
  intlMsg['const.lang.en'],
  intlMsg['const.lang.ry'],
  intlMsg['const.lang.hy'],
  intlMsg['const.lang.yny'],
  intlMsg['const.lang.mlxyy'],
  intlMsg['const.lang.jpzy'],
  intlMsg['const.lang.ydnxyy'],
  intlMsg['const.lang.fy'],
  intlMsg['const.lang.ty'],
  intlMsg['const.lang.ydy'],
  intlMsg['const.lang.flby'],
  intlMsg['const.lang.xbyy'],
  intlMsg['const.lang.ey'],
  intlMsg['const.lang.mdy'],
  intlMsg['const.lang.tmr'],
  intlMsg['const.lang.other'],
  intlMsg['const.lang.yday'],
  intlMsg['const.lang.cz'],
]

export const singerTypes = [
  intlMsg['com.song.all'],
  intlMsg['page.singer.cn-man'],
  intlMsg['page.singer.cn-woman'],
  intlMsg['page.singer.hk-man'],
  intlMsg['page.singer.hk-woman'],
  intlMsg['page.singer.tw-man'],
  intlMsg['page.singer.tw-woman'],
  intlMsg['page.singer.oversea-man'],
  intlMsg['page.singer.oversea-woman'],
  intlMsg['page.singer.other-man'],
  intlMsg['page.singer.other-woman'],
  intlMsg['page.singer.band'],
]

export const songTypes = [
  intlMsg['com.song.all'],
  ...langTypes.slice(1, 22)
]

export const categoryTypes = [
  {
    label: intlMsg['com.song.all'],
    img: img_all,
  },
  {
    label: intlMsg['const.type.img_fenlei01'],
    img: img_fenlei01,
  },
  {
    label: intlMsg['const.type.img_fenlei02'],
    img: img_fenlei02,
  },
  {
    label: intlMsg['const.type.img_fenlei03'],
    img: img_fenlei03,
  },
  {
    label: intlMsg['const.type.img_fenlei04'],
    img: img_fenlei04,
  },
  {
    label: intlMsg['const.type.img_fenlei05'],
    img: img_fenlei05,
  },
  {
    label: intlMsg['const.type.img_fenlei06'],
    img: img_fenlei06,
  },
  {
    label: intlMsg['const.type.img_fenlei07'],
    img: img_fenlei07,
  },
  {
    label: intlMsg['const.type.img_fenlei08'],
    img: img_fenlei08,
  },
  {
    label: intlMsg['const.type.img_fenlei09'],
    img: img_fenlei09,
  },
  {
    label: intlMsg['const.type.img_fenlei10'],
    img: img_fenlei10,
  },
  {
    label: intlMsg['const.type.img_fenlei11'],
    img: img_fenlei11,
  },
  {
    label: intlMsg['const.type.img_fenlei12'],
    img: img_fenlei12,
  },
  {
    label: intlMsg['const.type.img_fenlei13'],
    img: img_fenlei13,
  },
  {
    label: intlMsg['const.type.img_fenlei14'],
    img: img_fenlei14,
  },
  {
    label: intlMsg['const.type.img_fenlei15'],
    img: img_fenlei15,
  },
  {
    label: intlMsg['const.type.img_fenlei16'],
    img: img_fenlei16,
  },
  {
    label: intlMsg['const.type.img_fenlei17'],
    img: img_fenlei17,
  },
  {
    label: intlMsg['const.type.img_fenlei18'],
    img: img_fenlei18,
  },
  {
    label: intlMsg['const.type.img_fenlei19'],
    img: img_fenlei19,
  },
]

export const danceTypes = [
  {
    label: intlMsg['com.song.all'],
    img: img_all,
  },
  {
    label: intlMsg['const.type.img_dance02'],
    img: img_dance02,
  },
  {
    label: intlMsg['const.type.img_dance03'],
    img: img_dance03,
  },
  {
    label: intlMsg['const.type.img_dance04'],
    img: img_dance04,
  },
  {
    label: intlMsg['const.type.img_dance05'],
    img: img_dance05,
  },
  {
    label: intlMsg['const.type.img_dance06'],
    img: img_dance06,
  },
  {
    label: intlMsg['const.type.img_dance07'],
    img: img_dance07,
  },
  {
    label: intlMsg['const.type.img_dance08'],
    img: img_dance08,
  },
  {
    label: intlMsg['const.type.img_dance09'],
    img: img_dance09,
  },
  {
    label: intlMsg['const.type.img_dance10'],
    img: img_dance10,
  },
  {
    label: intlMsg['const.type.img_dance11'],
    img: img_dance11,
  },
  {
    label: intlMsg['const.type.img_dance12'],
    img: img_dance12,
  },
  {
    label: intlMsg['const.type.img_dance13'],
    img: img_dance13,
  },
  {
    label: intlMsg['const.type.img_dance14'],
    img: img_dance14,
  },
  {
    label: intlMsg['const.type.img_dance15'],
    img: img_dance15,
  },
]

export const rankTypes = [
  {
    label: intlMsg['const.type.img_paihang'],
    img: img_paihang,
  },
  {
    label: intlMsg['const.type.img_chinese'],
    img: img_chinese,
  },
  {
    label: intlMsg['const.type.img_yueyu'],
    img: img_yueyu,
  },
  {
    label: intlMsg['const.type.img_minnanyu'],
    img: img_minnanyu,
  },
  {
    label: intlMsg['const.type.img_english'],
    img: img_english,
  },
  {
    label: intlMsg['const.type.img_ktv'],
    img: img_ktv,
  },
  {
    label: intlMsg['const.type.img_hot_song'],
    img: img_hot_song,
  },
  {
    label: intlMsg['const.type.img_rock'],
    img: img_rock,
  },
]

export const hotTypes = [
  {
    label: intlMsg['com.song.all'],
    img: img_all,
  },
  {
    label: intlMsg['const.type.hot_zgyyggp'],
    img: hot_zgyyggp,
  },
  {
    label: intlMsg['const.type.hot_jdylc'],
    img: hot_jdylc,
  },
  {
    label: intlMsg['const.type.hot_wxgyj'],
    img: hot_wxgyj,
  },
  {
    label: intlMsg['const.type.hot_zgxsd'],
    img: hot_zgxsd,
  },
  {
    label: intlMsg['const.type.hot_mrzz'],
    img: hot_mrzz,
  },
  {
    label: intlMsg['const.type.hot_zgxsc'],
    img: hot_zgxsc,
  },
  {
    label: intlMsg['const.type.hot_ayzc'],
    img: hot_ayzc,
  },
  {
    label: intlMsg['const.type.hot_zydwm'],
    img: hot_zydwm,
  },
  {
    label: intlMsg['const.type.hot_srrx'],
    img: hot_srrx,
  },
  {
    label: intlMsg['const.type.hot_gs'],
    img: hot_gs,
  },
  {
    label: intlMsg['const.type.hot_zghsy'],
    img: hot_zghsy,
  },
  {
    label: intlMsg['const.type.hot_wwgk'],
    img: hot_wwgk,
  },
  {
    label: intlMsg['const.type.hot_zgzqy'],
    img: hot_zgzqy,
  },
  {
    label: intlMsg['const.type.hot_zgmzs'],
    img: hot_zgmzs,
  },
  {
    label: intlMsg['const.type.hot_zmhs'],
    img: hot_zmhs,
  },
  {
    label: intlMsg['const.type.hot_qnxz'],
    img: hot_qnxz,
  },
  {
    label: intlMsg['const.type.hot_zghgq'],
    img: hot_zghgq,
  },
  {
    label: intlMsg['const.type.hot_zgzx'],
    img: hot_zgzx,
  },
  {
    label: intlMsg['const.type.hot_wxhnc'],
    img: hot_wxhnc,
  },
  {
    label: intlMsg['const.type.hot_kjgw'],
    img: hot_kjgw,
  },
  {
    label: intlMsg['const.type.hot_ycgs'],
    img: hot_ycgs,
  },
  {
    label: intlMsg['const.type.hot_gsyx'],
    img: hot_gsyx,
  },
  {
    label: intlMsg['const.type.hot_zgxgs'],
    img: hot_zgxgs,
  },
  {
    label: intlMsg['const.type.hot_mmgw'],
    img: hot_mmgw,
  },
  {
    label: intlMsg['const.type.hot_tlzz'],
    img: hot_tlzz,
  },
  {
    label: intlMsg['const.type.hot_mmcj'],
    img: hot_mmcj,
  },
  {
    label: intlMsg['const.type.hot_mxdsy'],
    img: hot_mxdsy,
  },
  {
    label: intlMsg['const.type.hot_wlyyh'],
    img: hot_wlyyh,
  },
  {
    label: intlMsg['const.type.hot_lhlwdg'],
    img: hot_lhlwdg,
  },
  {
    label: intlMsg['const.type.hot_jql'],
    img: hot_jql,
  },
  {
    label: intlMsg['const.type.hot_zgyxh'],
    img: hot_zgyxh,
  },
]
