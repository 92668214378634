import React from 'react'
import Loadable from 'react-loadable'
import LazyLoading from '../components/LazyLoading';

export default function lazyComponent({ loader }: any) {
  return Loadable.Map({
    loader: {
      component: loader,
    },
    timeout: 10000,
    loading: LazyLoading,
    render(loaded: any, props) {
      const {
        component: { default: Component },
      } = loaded

      return <Component {...props} />
    },
  })
}
