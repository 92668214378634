export default {
  "com.title.home": "首页",
  "com.title.chosen": "已点",
  "com.title.more": "更多",
  "com.title.feedback": "求歌反馈", // new
  "com.title.set": "设置",
  "com.title.login": "登录", // new

  "com.user.connect": "更换房间",
  "com.user.disconnect": "未连接",
  "com.user.room-list": "包房列表",
  "com.user.table-list": "更换桌台",
  "com.user.call-service": "呼叫服务",
  "com.user.cancel-service": "取消服务",
  "com.user.logout": "退出登录",

  "com.song.name": "名称",
  "com.song.number": "编号",
  "com.song.language": "语言",
  "com.song.song": "歌名",
  "com.song.singer": "歌星",
  "com.song.up-tone": "升调",
  "com.song.down-tone": "降调",
  "com.song.accompaniment": "伴唱",
  "com.song.original": "原唱",
  "com.song.insert": "优先",
  "com.song.delete": "删除",
  "com.song.add-collect": "添加收藏",
  "com.song.select": "点歌",
  "com.song.ranking": "排行",
  "com.song.new-song": "新歌",
  "com.song.category": "分类",
  "com.song.dance": "舞曲",
  "com.song.hot": "热门",
  "com.song.collect": "收藏",
  "com.song.history": "点播记录",
  "com.song.all": "全部",
  "com.song.play-control": "播控",
  "com.song.next": "切歌",
  "com.song.play": "播放",
  "com.song.pause": "暂停",
  "com.song.restart": "重唱",
  "com.song.microphone": "麦克风",
  "com.song.music": "音乐",
  "com.song.tone": "音调",
  "com.song.spell": "简拼",
  "com.song.download": "下载",
  "com.song.downloading": "下载中",
  "com.song.edit": "修改",
  "com.song.playing": "正在播放",
  "com.song.new-ytb": "YouTube",

  "page.login.login-in": "登录",
  "page.login.hall": "大厅",
  "page.login.room": "包房",

  "page.chosen.order": "序列",
  "page.chosen.myself": "我桌",

  "page.setting.collect-code": "收藏码",
  "page.setting.version": "版本",

  "page.singer.cn-man": "中国男星",
  "page.singer.cn-woman": "中国女星",
  "page.singer.hk-man": "香港男星",
  "page.singer.hk-woman": "香港女星",
  "page.singer.tw-man": "台湾男星",
  "page.singer.tw-woman": "台湾女星",
  "page.singer.oversea-man": "海外男星",
  "page.singer.oversea-woman": "海外女星",
  "page.singer.other-man": "其他男星",
  "page.singer.other-woman": "其他女星",
  "page.singer.band": "组合",

  "tips.loading": "加载中",

  "tips.success.handle": "操作成功",
  "tips.success.collect": "收藏成功",
  "tips.success.clear-storage": "清除成功，请刷新页面",
  "tips.success.connect": "连接成功",
  "tips.success.set": "设置成功",

  "tips.warning.collect": "收藏已存在", // new
  "tips.warning.set-collect-code": "请先设置收藏码",
  "tips.warning.control-auth": "播放您点播的歌曲才可以操作！",
  "tips.warning.input-password": "请输入密码",
  "tips.warning.input-feedback": "请输入反馈内容",
  "tips.warning.input-connect-code": "请输入6位连接码",
  "tips.warning.table-closed": "桌台关闭",
  "tips.warning.room-closed": "包房关闭",
  "tips.warning.reconnect": "登录失效，请重新连接",
  "tips.warning.room-password-error": "包房密码错误", // new
  "tips.warning.link-again": "请先连接包房或桌台", // new
  "tips.warning.client-error": "客户端已经断开", // new
  "tips.warning.no-available": "不可操作", // new
  "tips.no-data": "暂无数据", // new

  "tips.error.network-fail": "网络开小差了～",
  "tips.error.page-lost": "额...页面走丢了～",
  "tips.error.connect-code": "连接码错误",
  "tips.error.token": "token错误",
  "tips.error.client-network": "网络异常",
  "tips.error.logic": "逻辑错误",
  "tips.error.query-params": "参数错误",
  "tips.error.query": "请求错误",
  "tips.error.server": "服务端错误",

  "btn.retry": "重试",
  "btn.confirm": "确定",
  "btn.cancel": "取消",
  "btn.submit": "提交",
  "btn.back-home": "返回首页",
  "btn.refresh": "刷新", // new
  "btn.clear-data": "清空数据", // new

  "const.lang.jtzw": "简体中文",
  "const.lang.gy": "国语",
  "const.lang.ftzw": "繁体中文",
  "const.lang.yy": "粤语",
  "const.lang.en": "英语",
  "const.lang.ydnxyy": "印尼语",
  "const.lang.mlxyy": "马来语",
  "const.lang.mny": "闽南语",
  "const.lang.ry": "日语",
  "const.lang.hy": "韩语",
  "const.lang.yny": "越南语",
  "const.lang.jpzy": "柬埔寨语",
  "const.lang.fy": "法语",
  "const.lang.ty": "泰语",
  "const.lang.ydy": "印度语",
  "const.lang.flby": "菲律宾语",
  "const.lang.xbyy": "西班牙语",
  "const.lang.ey": "俄语",
  "const.lang.mdy": "缅甸语",
  "const.lang.tmr": "泰米尔",
  "const.lang.yday": "印地语",
  "const.lang.cz": "潮州",
  "const.lang.other": "其他语言",

  "const.type.img_fenlei01": "合唱",
  "const.type.img_fenlei02": "革命歌曲",
  "const.type.img_fenlei03": "儿歌",
  "const.type.img_fenlei04": "怀旧",
  "const.type.img_fenlei05": "喜庆",
  "const.type.img_fenlei06": "粤剧",
  "const.type.img_fenlei07": "京剧",
  "const.type.img_fenlei08": "生日歌曲",
  "const.type.img_fenlei09": "越剧",
  "const.type.img_fenlei10": "黄梅戏",
  "const.type.img_fenlei11": "其他戏曲",
  "const.type.img_fenlei12": "潮剧",
  "const.type.img_fenlei13": "轻音乐",
  "const.type.img_fenlei14": "陕西秦腔",
  "const.type.img_fenlei15": "东北二人转",
  "const.type.img_fenlei16": "豫剧",
  "const.type.img_fenlei17": "西藏歌曲",
  "const.type.img_fenlei18": "新疆歌曲",
  "const.type.img_fenlei19": "HIFI音乐",

  "const.type.img_dance02": "串烧",
  "const.type.img_dance03": "迪士高",
  "const.type.img_dance04": "恰恰",
  "const.type.img_dance05": "普鲁士",
  "const.type.img_dance06": "探戈",
  "const.type.img_dance07": "华尔兹",
  "const.type.img_dance08": "吉特巴",
  "const.type.img_dance09": "慢摇",
  "const.type.img_dance10": "交际舞",
  "const.type.img_dance11": "夜店狂欢",
  "const.type.img_dance12": "迷幻电音",
  "const.type.img_dance13": "活力无限",
  "const.type.img_dance14": "清新电子",
  "const.type.img_dance15": "劲爆中文",

  "const.type.img_paihang": "总排行榜",
  "const.type.img_chinese": "国语榜",
  "const.type.img_yueyu": "粤语榜",
  "const.type.img_minnanyu": "闽南语榜",
  "const.type.img_english": "英语榜",
  "const.type.img_ktv": "KTV金曲榜",
  "const.type.img_hot_song": "热歌榜",
  "const.type.img_rock": "摇滚榜",

  "const.type.hot_zgyyggp": "中国音乐公告牌",
  "const.type.hot_jdylc": "经典永流传",
  "const.type.hot_wxgyj": "无限歌谣季",
  "const.type.hot_zgxsd": "中国新声代",
  "const.type.hot_mrzz": "明日之子",
  "const.type.hot_zgxsc": "中国新说唱",
  "const.type.hot_ayzc": "幻乐之城",
  "const.type.hot_zydwm": "最优的我们",
  "const.type.hot_srrx": "声入人心",
  "const.type.hot_gs": "我是歌手",
  "const.type.hot_zghsy": "中国好声音",
  "const.type.hot_wwgk": "我为歌狂",
  "const.type.hot_zgzqy": "中国最强音",
  "const.type.hot_zgmzs": "中国梦之声",
  "const.type.hot_zmhs": "最美和声",
  "const.type.hot_qnxz": "全能星战",
  "const.type.hot_zghgq": "中国好歌曲",
  "const.type.hot_zgzx": "中国之星",
  "const.type.hot_wxhnc": "我想和你唱",
  "const.type.hot_kjgw": "跨界歌王",
  "const.type.hot_ycgs": "隐藏的歌手",
  "const.type.hot_gsyx": "盖世英雄",
  "const.type.hot_zgxgs": "中国新歌声",
  "const.type.hot_mmgw": "蒙面歌王",
  "const.type.hot_tlzz": "天籁之战",
  "const.type.hot_mmcj": "蒙面唱将",
  "const.type.hot_mxdsy": "梦想的声音",
  "const.type.hot_wlyyh": "围炉音乐会",
  "const.type.hot_lhlwdg": "厉害了我的歌",
  "const.type.hot_jql": "金曲捞",
  "const.type.hot_zgyxh": "中国有嘻哈",
}
