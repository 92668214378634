import { observable, action, runInAction } from 'mobx'
import BaseStore from './baseStore'
import { getName, getType } from '@/utils/userHelper'
import storage, { localStorageGetItem, localStorageSetItem } from '@/utils/storage'

class UserStore extends BaseStore {
  constructor() {
    super()
  }

  @observable
  language: string = localStorageGetItem(storage.KEYS.language) || 'en_US'

  @observable
  intlMessages: any = {}

  @observable
  userConnectStatus = false

  @observable
  colletionCode = ''

  @observable
  connectName = ''

  @observable
  connectType = ''

  @observable
  sn = ''

  @action
  switchLanguage(lang: string) {
    console.log(lang);
    // this.language = lang
    // this.intlMessages = {}
    localStorageSetItem(storage.KEYS.language, lang)
    // TODO: 暂时刷新页面更新语言
    window.location.reload()
  }

  @action
  updateUserConnectStatus(val: boolean = true) {
    this.userConnectStatus = val

    if (val) {
      getName().then((val) => {
        runInAction(() => {
          this.connectName = val
        })
      })

      getType().then((val) => {
        runInAction(() => {
          this.connectType = val
        })
      })
    }
  }
}

export default UserStore
