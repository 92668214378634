import { routes } from './routes'
import RouteExtends from './routeExtends'
import { RoutePropsType, RouterType, RoutesKey } from './PropsType'

const routesInstance = <RouterType>{}

routes.map((item: RoutePropsType) => {
  routesInstance[<RoutesKey>item.name] = new RouteExtends(item)
})

export function getRoutes() {
  return routesInstance
}

export function getRoutesAttrs() {
  return Object.values(routesInstance).map((item: any) => item.attrs)
}

export { default as history } from './history'
export { default as RouteWrap } from './RouteWrap'

