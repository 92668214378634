import { EventEmitter2 } from 'eventemitter2';

export const eventEmitter = new EventEmitter2()

export const eventEmitter_KEYS = {
  clearSearchBarText: 'clearSearchBarText',
  updateColletionCode: 'updateColletionCode',
  refreshSongList: 'refreshSongList',
  disconnect: 'disconnect',
  connect: 'connect',
}
