import React from 'react';
import { Modal, Card, List } from 'antd-mobile';

import { hallTableInfo } from '@/api/api';
import AppList from '../AppList';
import { useStore } from '@/store';
import { observer } from 'mobx-react-lite';
import { userConnect } from '@/utils/userHelper';

import './index.scss'

interface TableListModalProps {}

const TableListModal: React.FC<TableListModalProps> = (props) => {
  const { headerStore } = useStore()


  const handleClickTable = (data: any, machineName: string) => {
    userConnect('', {
      machineName: machineName,
      name: data.table_name,
      token: data.token,
      linked: data.linked,
      type: "table",
    })

    headerStore.updateTableListModalVisible(false)
  }

  const Row = (props: any) => {
    const { source } = props
    return (
      <Card>
        <Card.Header title={source.machineName} />
        <Card.Body>
          <List>
            {
              (source.list || []).map((item: any, index: number) => <List.Item onClick={() => handleClickTable(item, source.machineName)} key={index}>{item.table_name}</List.Item>)
            }
          </List>
        </Card.Body>
      </Card>
    )
  }

  const handleQuery = async () => {
    const data = await hallTableInfo()
    return data.halllist
  }

  return (
    <Modal closable popup visible={headerStore.tableListModalVisible} onClose={() => headerStore.updateTableListModalVisible(false)} animationType="slide-up" className="table-list-wrap">
      <AppList row={Row} request={handleQuery} type="custom" className="table-list-inner" />
    </Modal>
  );
};

export default observer(TableListModal);
