import React, { ImgHTMLAttributes, useState } from 'react';
import defaultImg from '@/assets/loading.gif';

interface AppImgProps extends ImgHTMLAttributes<any> {
  loading?: boolean
}

const AppImg: React.FC<AppImgProps> = (props) => {
  const { onClick, src = defaultImg, loading = false, ...rest } = props
  const [ imgSrc, setImgSrc ] = useState(defaultImg)

  const onLoaded = () => {
    setImgSrc(src)
  }

  return (
    <div onClick={onClick}>
      <img alt='' src={loading ? imgSrc : src} onLoad={loading ? onLoaded : () => {}} {...rest} />
    </div>
  );
};

export default AppImg;
