import React, { Component } from "react";

import btn_control from '../../assets/images/yk.png';

import "./index.scss";
import RemoteControlModal from "./RemoteControlModal";
import { eventEmitter, eventEmitter_KEYS } from "@/utils/eventEmitter";

const RIGHT = 10;
const BOTTOM = 110;

class RemoteControl extends Component {
  constructor(props) {
    super(props);
    this.hasMove = false;
    this.state = {
      isOpen: false
    };
    this.switchPos = {
      x: RIGHT, // right
      y: BOTTOM, // bottom
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0
    };
    this.myRef = React.createRef();
  }
  componentDidMount() {
    this.iniSwitch();
    eventEmitter.addListener(eventEmitter_KEYS.disconnect, () => {
      this.setState({ isOpen: false })
    })
  }
  componentWillUnmount() {
    eventEmitter.removeAllListeners(eventEmitter_KEYS.disconnect)
  }
  iniSwitch() {
    const $switch = this.myRef.current;
    const switchPos = {};
    let switchX = switchPos.x;
    let switchY = switchPos.y;
    if (switchX || switchY) {
      // check edge
      if (
        switchX + $switch.offsetWidth >
        document.documentElement.offsetWidth
      ) {
        switchX = document.documentElement.offsetWidth - $switch.offsetWidth;
      }
      if (
        switchY + $switch.offsetHeight >
        document.documentElement.offsetHeight
      ) {
        switchY = document.documentElement.offsetHeight - $switch.offsetHeight;
      }
      if (switchX < 0) {
        switchX = 0;
      }
      if (switchY < 0) {
        switchY = 0;
      }
      this.switchPos.x = switchX;
      this.switchPos.y = switchY;
    }
    $switch.style.right = this.switchPos.x + "px";
    $switch.style.bottom = this.switchPos.y + "px";
  }

  touchstart = e => {
    this.switchPos.startX = e.touches[0].pageX;
    this.switchPos.startY = e.touches[0].pageY;
  };

  touchend = e => {
    if (!this.hasMove) {
      this.switchNav(e);
      this.hasMove = false;
      return;
    }
    this.hasMove = false;
    this.switchPos.x = this.switchPos.endX;
    this.switchPos.y = this.switchPos.endY;
    this.switchPos.startX = 0;
    this.switchPos.startY = 0;
    this.switchPos.endX = 0;
    this.switchPos.endY = 0;
  };

  touchmove = e => {
    const $switch = this.myRef.current;

    if (e.touches.length > 0) {
      let offsetX = e.touches[0].pageX - this.switchPos.startX,
        offsetY = e.touches[0].pageY - this.switchPos.startY;
      let x = this.switchPos.x - offsetX,
        y = this.switchPos.y - offsetY;
      this.hasMove = true;

      // check edge
      if (x + $switch.offsetWidth > document.documentElement.offsetWidth) {
        x = document.documentElement.offsetWidth - $switch.offsetWidth;
      }
      if (y + $switch.offsetHeight > document.documentElement.clientHeight) {
        y = document.documentElement.clientHeight - $switch.offsetHeight;
      }
      if (x < 0) {
        x = 0;
      }
      if (y < 0) {
        y = 0;
      }
      $switch.style.right = x + "px";
      $switch.style.bottom = y + "px";
      this.switchPos.endX = x;
      this.switchPos.endY = y;
      e.preventDefault();
    }
  };

  switchNav = e => {
    e && e.stopPropagation();
    e && e.preventDefault();
    this.setState({
      isOpen: true
    });
  };

  render() {
    return (
      <>
        <div
          className="remote-control-shortcut"
          ref={this.myRef}
          onTouchStart={this.touchstart}
          onTouchMove={this.touchmove}
          onTouchEnd={this.touchend}
        >
          <img src={btn_control} alt="remote control btn"/>
        </div>
        <RemoteControlModal visible={this.state.isOpen} onClose={() => this.setState({ isOpen: false })}/>
      </>
    );
  }
}

export default RemoteControl;
