import axios, { AxiosRequestConfig } from 'axios'
import appConfig from '@/config'
import { Toast } from 'antd-mobile'
import {
  getUserName,
  getSN,
  getToken,
  getMachineName,
  getType,
  getName,
  getLinked,
} from '@/utils/userHelper'
import { ToastFail } from '@/utils/utils'
import { userDisconnect } from "@/utils/userHelper";
import { getFormatMessage } from '@/locales'

interface RequestConfig extends AxiosRequestConfig {
  loading?: boolean
}

const requestInstance = axios.create({
  baseURL: appConfig.REACT_APP_baseUrl + '/demo.cgi',
  timeout: 30000,
})

const handleRequestHeader = (config: RequestConfig) => {
  return {
    ...config.headers
  }
}

const handleRequestData = async (config: RequestConfig) => {
  return {
    machineName: await getMachineName(),
    type: await getType() || '',
    name: await getName() || '',
    token: await getToken() || '',
    SN: await getSN() || '',
    userName: await getUserName() || 'linkaraok',
    linked: await getLinked() || '',
    msgType: config.url,
    ...config.data,
  }
}

requestInstance.interceptors.request.use(
  async config => {
    return {
      ...config,
      url: '',
      headers: handleRequestHeader(config),
      data: await handleRequestData(config),
    }
  },
  error => {
    const intlMsg = getFormatMessage()

    ToastFail(intlMsg['tips.error.query'])
    return Promise.reject(error)
  },
)

requestInstance.interceptors.response.use(
  response => {
    const { data } = response
    const intlMsg = getFormatMessage()
    const errorMsgMap: any = {
      linkcode_error: intlMsg['tips.error.connect-code'],
      table_closed: intlMsg['tips.warning.table-closed'],
      room_closed: intlMsg['tips.warning.room-closed'],
      token_error: intlMsg['tips.error.token'],
      network_error: intlMsg['tips.error.client-network'],
      logic_error: intlMsg['tips.error.logic'],
      parameter_error: intlMsg['tips.error.query-params'],
      room_password_error: intlMsg['tips.warning.room-password-error'],
      link_again: intlMsg['tips.warning.link-again'],
      client_error: intlMsg['tips.warning.client-error'],
      no_available: intlMsg['tips.warning.no-available'],
    }

    if (data.msgType === 'errorMsg') {
      const errorType = data.error_msg
      const msg = errorMsgMap[errorType] || intlMsg['tips.error.server']

      ToastFail(msg)

      // token 错误处理
      if (errorType === 'token_error') {
        ToastFail(intlMsg['tips.warning.reconnect'])
        userDisconnect()
      }

      return Promise.reject(data)
    }

    return data
  },
  error => {
    return Promise.reject({
      error,
      type: 'response'
    })
  },
)

const handleRequest = async (config: RequestConfig) => {
  const { loading = false } = config
  const intlMsg = getFormatMessage()
  let isError = false

  loading && Toast.loading(intlMsg['tips.loading'], 0)

  const data: any = await requestInstance
    .request(config)
    .catch(e => {
      const { type } = e
      isError = true

      if (type === 'response') {
        loading && ToastFail(intlMsg['tips.error.client-network'])
      }

      return Promise.reject(e)
    })
    .finally(() => {
      loading && !isError && Toast.hide()
    })

  return data
}

const post = (url: string, data?: object, config?: RequestConfig) => {
  return handleRequest({
    url,
    data,
    method: 'post',
    ...config,
  })
}

const get = (url: string, params?: object, config?: RequestConfig) => {
  return handleRequest({
    url,
    params,
    method: 'get',
    ...config,
  })
}

export const generateCancelToken = () => {
  const CancelToken = axios.CancelToken
  return CancelToken.source()
}

export const isCancel = axios.isCancel

export default {
  post,
  get,
}
