import request from './request'

export const phoneConnect = async (params = {}, config = {}) => {
  return request.post(
    'phoneConnect',
    params,
    {
      loading: true,
      ...config
    },
  )
}

export const snRequest = async (params = {}, config = {}) => {
  return request.post(
    'SnRequest',
    params,
    {
      loading: true,
      ...config
    },
  )
}

export const searchSinger = async (params = {}) => {
  return request.post('searchSinger', params)
}

export const searchSong = async (params = {}) => {
  return request.post('searchSong', params)
}

export const orderSong = async (params: any) => {
  return request.post('orderSong', params, {
    loading: true,
  })
}

export const orderSongEdit = async (params: any) => {
  return request.post('orderSongEdit', params, {
    loading: true,
  })
}

export const orderList = async (params = {}, config = {}) => {
  return request.post('orderList', params, config)
}

export const tablePlayList = async (params = {}, config = {}) => {
  return request.post('tablePlayList', params, config)
}

export const tableAllList = async (params = {}, config = {}) => {
  return request.post('tableAllList', params, config)
}

export const roomListInfo = async (params = {}, config = {}) => {
  return request.post('roomListInfo', params, config)
}

export const roomEnterPasswd = async (params = {}, config = {}) => {
  return request.post('roomEnterPasswd', params, config)
}

export const hallTableInfo = async (params = {}, config = {}) => {
  return request.post('hallTableInfo', params, config)
}

export const playControl = async (params = {}, config = {}) => {
  return request.post('playControl', params, {
    loading: true,
    ...config,
  })
}

export const lightControl = async (params = {}, config = {}) => {
  return request.post('lightControl', params, {
    loading: true,
    ...config,
  })
}

export const songHot = async (params = {}, config = {}) => {
  return request.post('songHot', params, config)
}

export const singerHot = async (params = {}, config = {}) => {
  return request.post('singerHot', params, config)
}

export const newSongName = async (params = {}, config = {}) => {
  return request.post('newSongName', params, {
    loading: true,
    ...config
  })
}

export const newYtbName = async (params = {}, config = {}) => {
  return request.post('newYtbName', params, {
    loading: true,
    ...config
  })
}

export const favariteAdd = async (params = {}, config = {}) => {
  return request.post('favariteAdd', params, {
    loading: true,
    ...config
  })
}

export const favariteDel = async (params = {}, config = {}) => {
  return request.post('favariteDel', params, {
    loading: true,
    ...config
  })
}

export const callService = async (params = {}, config = {}) => {
  return request.post('callService', params, {
    loading: true,
    ...config
  })
}

export const cancelService = async (params = {}, config = {}) => {
  return request.post('cancelService', params, {
    loading: true,
    ...config
  })
}

export const playModeStatus = async (params = {}, config = {}) => {
  return request.post('playModeStatus', params, config)
}

export const submitFeedBack = async (params = {}, config = {}) => {
  return request.post('feedBack', params, config)
}
