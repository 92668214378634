import React from 'react'
import { Button } from 'antd-mobile'

import './index.scss'
import { useFormatMessage } from '@/locales';

interface FailTipsProps extends GT.ComponentProps {
  text?: string
  buttonText?: string
  buttonType?: 'primary' | 'warning' | 'ghost'
  buttonSize?: 'large' | 'small';
  onClick?: () => void
}

const FailTips: React.FC<FailTipsProps> = (props) => {
  const intlMsg = useFormatMessage()
  const { text = intlMsg['tips.error.network-fail'], buttonText = intlMsg['btn.retry'], buttonSize="large", buttonType="primary", onClick } = props

  return (
    <div className="fail-tips-wrap">
      <div className="fail-tips-inner">
        <p>
          {text}
        </p>
        <Button size={buttonSize} type={buttonType} onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default FailTips;
