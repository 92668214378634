export default {
  "com.title.home": "Home",
  "com.title.chosen": "Selected",
  "com.title.more": "More",
  "com.title.feedback": "Feedback", // new
  "com.title.set": "Setting",
  "com.title.login": "Log in", // new

  "com.user.connect": "Change Room",
  "com.user.disconnect": "Disconnect",
  "com.user.room-list": "Room List",
  "com.user.table-list": "Change Table",
  "com.user.call-service": "Call Service",
  "com.user.cancel-service": "Cancel Service",
  "com.user.logout": "Logout",

  "com.song.name": "Name",
  "com.song.number": "Number",
  "com.song.language": "Language",
  "com.song.song": "Song",
  "com.song.singer": "Singer",
  "com.song.up-tone": "Up Tone",
  "com.song.down-tone": "Down Tone",
  "com.song.accompaniment": "Digital",
  "com.song.original": "Analog",
  "com.song.insert": "Insert",
  "com.song.delete": "Delete",
  "com.song.add-collect": "Add Favorite",
  "com.song.select": "Select",
  "com.song.ranking": "Ranking",
  "com.song.new-song": "New Song",
  "com.song.category": "Type",
  "com.song.dance": "Dance",
  "com.song.hot": "Popular",
  "com.song.collect": "Favorite",
  "com.song.history": "History",
  "com.song.all": "All",
  "com.song.play-control": "PLAY CONTROL",
  "com.song.next": "NEXT",
  "com.song.play": "PLAY",
  "com.song.pause": "PAUSE",
  "com.song.restart": "RESTART",
  "com.song.microphone": "Microphone",
  "com.song.music": "Music",
  "com.song.tone": "Tone",
  "com.song.spell": "SPELL",
  "com.song.download": "Download",
  "com.song.downloading": "Downloading",
  "com.song.edit": "Edit",
  "com.song.playing": "Now Playing",
  "com.song.new-ytb": "YouTube",
  
  "page.login.login-in": "Log in",
  "page.login.hall": "Hall",
  "page.login.room": "Room",

  "page.chosen.order": "Sequence",
  "page.chosen.myself": "My Table",

  "page.setting.collect-code": "Favorite Code",
  "page.setting.version": "Version",

  "page.singer.cn-man": "Chinese Male",
  "page.singer.cn-woman": "Chinese Female",
  "page.singer.hk-man": "Hong Kong Male",
  "page.singer.hk-woman": "Hong Kong Female",
  "page.singer.tw-man": "Taiwan Male",
  "page.singer.tw-woman": "Taiwan Female",
  "page.singer.oversea-man": "Oversea Male",
  "page.singer.oversea-woman": "Oversea Female",
  "page.singer.other-man": "Other Male",
  "page.singer.other-woman": "Other Female",
  "page.singer.band": "Bands",

  "tips.loading": "Loading",

  "tips.success.handle": "Successful Handle",
  "tips.success.collect": "Collection Success",
  "tips.success.clear-storage": "Cleared Successfully,Please Refresh New Page",
  "tips.success.connect": "Connect Succeeded",
  "tips.success.set": "Setting Successfully",

  "tips.warning.collect": "收藏已存在", // new
  "tips.warning.set-collect-code": "Please set your favorite code",
  "tips.warning.control-auth": "Only play your On-Demand Songs",
  "tips.warning.input-password": "Enter the Password",
  "tips.warning.input-feedback": "Please enter feedback",
  "tips.warning.input-connect-code": "Please enter 6 Digit Connection Code",
  "tips.warning.table-closed": "Table Closed",
  "tips.warning.room-closed": "Room Closed",
  "tips.warning.reconnect": "Log in Failed,Please Reconnect",
  "tips.warning.room-password-error": "包房密码错误", // new
  "tips.warning.link-again": "请先连接包房或桌台", // new
  "tips.warning.client-error": "客户端已经断开", // new
  "tips.warning.no-available": "不可操作", // new
  "tips.no-data": "No Result!", // new

  "tips.error.network-fail": "Network Fail~",
  "tips.error.page-lost": "Page is Lost ～",
  "tips.error.connect-code": "Connection Code Error",
  "tips.error.token": "Token Error ",
  "tips.error.client-network": "Network Abnormal",
  "tips.error.logic": "Logical Error",
  "tips.error.query-params": "Parameter Error",
  "tips.error.query": "Request Error",
  "tips.error.server": "Server Error",

  "btn.retry": "Retry",
  "btn.confirm": "Confirm",
  "btn.cancel": "Cancel",
  "btn.submit": "Submit",
  "btn.back-home": "Back Home",
  "btn.refresh": "Update", // new
  "btn.clear-data": "Clear cache", // new

  "const.lang.jtzw": "Simplified Chinese",
  "const.lang.gy": "Mandarin",
  "const.lang.ftzw": "Traditional Chinese",
  "const.lang.yy": "Cantonese",
  "const.lang.en": "English",
  "const.lang.ydnxyy": "Indonesian",
  "const.lang.mlxyy": "Malay",
  "const.lang.mny": "Minnan",
  "const.lang.ry": "Japanese",
  "const.lang.hy": "Korean",
  "const.lang.yny": "Vietnamese",
  "const.lang.jpzy": "Cambodian",
  "const.lang.fy": "French",
  "const.lang.ty": "Thai",
  "const.lang.ydy": "Hindi",
  "const.lang.flby": "Filipino",
  "const.lang.xbyy": "Spanish",
  "const.lang.ey": "Russian",
  "const.lang.mdy": "Burmese",
  "const.lang.tmr": "Tamil",
  "const.lang.yday": "Hindi",
  "const.lang.cz": "Chaozhou",
  "const.lang.other": "Others",

  "const.type.img_fenlei01": "Chorus",
  "const.type.img_fenlei02": "Revolutionary Songs",
  "const.type.img_fenlei03": "Children's",
  "const.type.img_fenlei04": "Nostalgia",
  "const.type.img_fenlei05": "Festive",
  "const.type.img_fenlei06": "Cantonese Opera",
  "const.type.img_fenlei07": "Peking Opera",
  "const.type.img_fenlei08": "Birthday",
  "const.type.img_fenlei09": "Yue Opera",
  "const.type.img_fenlei10": "Huangmei Opera",
  "const.type.img_fenlei11": "Others",
  "const.type.img_fenlei12": "Chao Opera",
  "const.type.img_fenlei13": "Light Music",
  "const.type.img_fenlei14": "Shaanxi Qin Qiang",
  "const.type.img_fenlei15": "Northeast Duozhuan",
  "const.type.img_fenlei16": "Yu Opera",
  "const.type.img_fenlei17": "Tibetan Songs",
  "const.type.img_fenlei18": "Xinjiang Songs",
  "const.type.img_fenlei19": "HIFI Music",

  "const.type.img_dance02": "Medley",
  "const.type.img_dance03": "Disco",
  "const.type.img_dance04": "Cha-Cha",
  "const.type.img_dance05": "Prussian",
  "const.type.img_dance06": "Tango",
  "const.type.img_dance07": "Waltz",
  "const.type.img_dance08": "Jitterbug",
  "const.type.img_dance09": "Slow Roll",
  "const.type.img_dance10": "Ballroom Dance",
  "const.type.img_dance11": "Nightclub",
  "const.type.img_dance12": "EDM",
  "const.type.img_dance13": "Energetic",
  "const.type.img_dance14": "Fresh Electronic",
  "const.type.img_dance15": "Madden Chinese",

  "const.type.img_paihang": "Top",
  "const.type.img_chinese": "Chinese",
  "const.type.img_yueyu": "Yueyu",
  "const.type.img_minnanyu": "Minnan",
  "const.type.img_english": "English",
  "const.type.img_ktv": "KTV",
  "const.type.img_hot_song": "Hot",
  "const.type.img_rock": "Rock",

  "const.type.hot_zgyyggp": "Idol Hits",
  "const.type.hot_jdylc": "Everlasting Classics",
  "const.type.hot_wxgyj": "Unlimited Song Season",
  "const.type.hot_zgxsd": "Let's Sing Kid's",
  "const.type.hot_mrzz": "The Coming One",
  "const.type.hot_zgxsc": "The RAP Of China",
  "const.type.hot_ayzc": "Phantacity",
  "const.type.hot_zydwm": "Best Us",
  "const.type.hot_srrx": "Super Vocal",
  "const.type.hot_gs": "I'm Singer",
  "const.type.hot_zghsy": "Best Music",
  "const.type.hot_wwgk": "Mad Of Music",
  "const.type.hot_zgzqy": "The X Factor",
  "const.type.hot_zgmzs": "Chinese Idol",
  "const.type.hot_zmhs": "Duets",
  "const.type.hot_qnxz": "All Wars",
  "const.type.hot_zghgq": "Song Of China",
  "const.type.hot_zgzx": "China Star",
  "const.type.hot_wxhnc": "Come Sing With Me",
  "const.type.hot_kjgw": "King Cross",
  "const.type.hot_ycgs": "Hidden Singer",
  "const.type.hot_gsyx": "The Remix",
  "const.type.hot_zgxgs": "Sing!China",
  "const.type.hot_mmgw": "Masked King",
  "const.type.hot_tlzz": "The Sounds Of War",
  "const.type.hot_mmcj": "Masked Singer",
  "const.type.hot_mxdsy": "Sound Of My Dream",
  "const.type.hot_wlyyh": "The Recycling Concert",
  "const.type.hot_lhlwdg": "Wow!My Song",
  "const.type.hot_jql": "The Golden Melody",
  "const.type.hot_zgyxh": "The RAP Of China",
}
