import React, { useEffect } from 'react'
import { Player as VideoPlayer, ControlBar, LoadingSpinner } from 'video-react'

import './index.scss'

interface PlayerProps extends React.MediaHTMLAttributes<any> {
  source: string
}

let vplayer: any

const Player: React.FC<PlayerProps> = props => {
  const { source } = props

  useEffect(() => {
    vplayer.play()
    vplayer.subscribeToStateChange((state: any) => {
      if (state.ended) {
        vplayer.play()
      }
    })
  }, [])

  return (
    // TODO: 这里使用的 video-react 包，无法解决安卓浏览器禁止视频自动全屏
    // 所以手动修改了 video-react/lib/components/Video.js 文件
    // 增加了以下两个属性：
    // "x5-playsinline": "true",
    // "webkit-playsinline": "true",
    <VideoPlayer
      playsInline
      fluid={false}
      width="100%"
      height={200}
      src={source}
      ref={(player: any) => {
        vplayer = player
      }}
    >
      <ControlBar disableCompletely />
      <LoadingSpinner />
    </VideoPlayer>
  )
}

export default Player
