export function isWechat(ua = navigator.userAgent) {
  return /MicroMessenger/i.test(ua.toLowerCase());
}

export function isDingTalk(ua = navigator.userAgent) {
  return /DingTalk/i.test(ua.toLowerCase());
}

export function isIOS(ua = navigator.userAgent) {
  return /iPad|iPhone|iPod/i.test(ua.toLowerCase());
}

export function isAndroid(ua = navigator.userAgent) {
  return /Android/i.test(ua.toLowerCase());
}

export function isChrome(ua = navigator.userAgent) {
  return /Chrome/i.test(ua.toLowerCase());
}
