import React, { useEffect } from 'react'
import { WhiteSpace, Flex, Modal } from 'antd-mobile'

import icon_banchang from '@/assets/images/icon_banchang.png'
import icon_yuanchang_pre1 from '@/assets/images/icon_yuanchang_pre1.png'

import icon_nextsong from '@/assets/images/icon_nextsong.png'

import icon_suspend from '@/assets/images/icon_suspend.png'
import icon_play from '@/assets/images/icon_play.png'

import icon_duet from '@/assets/images/icon_duet.png'

// import icn_dynamic_nor from '@/assets/images/icn_dynamic_nor.png'
// import icn_dynamic_pre from '@/assets/images/icn_dynamic_pre.png'

// import icon_auto_nor from '@/assets/images/icon_auto_nor.png'
// import icon_auto_pre from '@/assets/images/icon_auto_pre.png'

// import icon_bright_nor from '@/assets/images/icon_bright_nor.png'
// import icon_bright_pre from '@/assets/images/icon_bright_pre.png'

// import icon_close_nor from '@/assets/images/icon_close_nor.png'
// import icon_close_pre from '@/assets/images/icon_close_pre.png'

// import icon_kge_nor from '@/assets/images/icon_kge_nor.png'
// import icon_kge_pre from '@/assets/images/icon_kge_pre.png'

// import icon_soft_nor from '@/assets/images/icon_soft_nor.png'
// import icon_soft_pre from '@/assets/images/icon_soft_pre.png'

import btn_plus_pre from '@/assets/images/btn_plus_pre.png'
import btn_reduce_pre from '@/assets/images/btn_reduce_pre.png'
import btn_hoisting_pre from '@/assets/images/btn_hoisting_pre.png'
import btn_demotion_pre from '@/assets/images/btn_demotion_pre.png'

import './index.scss'
import { ModalProps } from 'antd-mobile/lib/modal/Modal';
import { playControl, lightControl } from '@/api/api'
import { useLocalStore, observer } from 'mobx-react-lite'
import AppImg from '@/components/AppImg'
import { ToastSuccess } from '@/utils/utils'
import { useStore } from '@/store'
import { getSN } from '@/utils/userHelper'
import { useFormatMessage } from '@/locales'

interface RemoteControlModalProps extends ModalProps {
}

const RemoteControlModal: React.FC<RemoteControlModalProps> = props => {
  const { visible, onClose = () => {} } = props
  const ctrl = useLocalStore(() => {
    return {
      voice: false, // false 伴唱 true 原唱
      play: true,
      lightStatus: 1,
      disabledControl: false,
    }
  })

  const { songStore, userStore } = useStore()
  const intlMsg = useFormatMessage()

  useEffect(() => {
    const fn = async () => {
      if (userStore.connectType === 'table') {
        const sn = await getSN()
        const src_mac = songStore.currentPlayerSongData.src_mac

        if (sn !== src_mac) {
          ctrl.disabledControl = true
        }
      }
    }

    fn()

    return () => {
      ctrl.disabledControl = false
    }
  }, [visible])

  const handleFetchPlayControl = async (mode: string) => {
    await playControl({
      mode
    })
  }

  const handleFetchLightControl = async (cmd: any) => {
    await lightControl({
      cmd
    })
  }

  // const handleLightControl = async (cmd: any) => {
  //   await handleFetchLightControl(cmd)
  //   ctrl.lightStatus = cmd
  // }

  const handlePlayControl = async (mode: string) => {
    await handleFetchPlayControl(mode)
    ToastSuccess(intlMsg['tips.success.handle'])

    if (mode === 'accomp' || mode === 'original') {
      ctrl.voice = mode === 'original'
    }

    if (mode === 'pause' || mode === 'play') {
      ctrl.play = mode === 'play'
    }
  }

  return (
    <Modal closable popup visible={visible} onClose={onClose} animationType="slide-up">
      <div className="remote-control-wrap">
        <div className="section-title"><span>{intlMsg['com.song.play-control']}</span></div>
        <Flex justify="around" align="start" className="main-control-section">
          <Flex direction="column" onClick={() => handlePlayControl(ctrl.voice ? 'accomp' : 'original')}>
            <a className="control-button"><AppImg className="main-control-icon" src={ctrl.voice ? icon_banchang : icon_yuanchang_pre1} /></a>
            {/* <span>{ctrl.voice ? '伴' : '原'}唱</span> */}
            <span>{intlMsg['com.song.original']}/{intlMsg['com.song.accompaniment']}</span>
          </Flex>
          <Flex direction="column" onClick={() => handlePlayControl('next')}>
            <a className="control-button"><AppImg className="main-control-icon" src={icon_nextsong} /></a>
            <span>{intlMsg['com.song.next']}</span>
          </Flex>
          <Flex direction="column" onClick={() => handlePlayControl(ctrl.play ? 'pause' : 'play')}>
            <a className="control-button"><AppImg className="main-control-icon" src={ctrl.play ? icon_suspend : icon_play} /></a>
            {/* <span>{ctrl.play ? '暂停' : '播放'}</span> */}
            <span>{intlMsg['com.song.play']}/{intlMsg['com.song.pause']}</span>
          </Flex>
          <Flex direction="column" onClick={() => handlePlayControl('repeate')}>
            <a className="control-button"><AppImg className="main-control-icon" src={icon_duet} /></a>
            <span>{intlMsg['com.song.restart']}</span>
          </Flex>
        </Flex>
        <div className="section-title"><span></span></div>
        <Flex justify="around" className="adjuster-section">
          <Flex direction="column" justify="between">
            <Flex direction="column" justify="between" className="adjuster-item">
              <a className="control-button"><AppImg className="adjuster-icon" src={btn_plus_pre} onClick={() => handlePlayControl('mic_up')}/></a>
                <span>-</span>
              <a className="control-button"><AppImg className="adjuster-icon" src={btn_reduce_pre} onClick={() => handlePlayControl('mic_dn')}/></a>
            </Flex>
            <span>{intlMsg['com.song.microphone']}</span>
          </Flex>
          <Flex direction="column" justify="between">
            <Flex direction="column" justify="between" className="adjuster-item">
            <a className="control-button"><AppImg className="adjuster-icon" src={btn_plus_pre} onClick={() => handlePlayControl('vol_up')}/></a>
                <span>-</span>
                <a className="control-button"><AppImg className="adjuster-icon" src={btn_reduce_pre} onClick={() => handlePlayControl('vol_dn')}/></a>
            </Flex>
            <span>{intlMsg['com.song.music']}</span>
          </Flex>
          <Flex direction="column" justify="between">
            <Flex direction="column" justify="between" className="adjuster-item">
            <a className="control-button"><AppImg className="adjuster-icon" src={btn_hoisting_pre} onClick={() => handlePlayControl('key_up')}/></a>
                <span>0</span>
                <a className="control-button"><AppImg className="adjuster-icon" src={btn_demotion_pre} onClick={() => handlePlayControl('key_dn')}/></a>
            </Flex>
            <span>{intlMsg['com.song.tone']}</span>
          </Flex>
        </Flex>
        {/* <div className="section-title"><span>灯光</span></div>
        <Flex justify="around" className="light-control-section">
          <Flex direction="column" onClick={() => handleLightControl(1)}>
            <AppImg className="light-control-icon" src={ctrl.lightStatus === 1 ? icn_dynamic_pre : icn_dynamic_nor} />
            <span>动感</span>
          </Flex>
          <Flex direction="column" onClick={() => handleLightControl(2)}>
            <AppImg className="light-control-icon" src={ctrl.lightStatus === 2 ? icon_soft_pre : icon_soft_nor} />
            <span>柔和</span>
          </Flex>
          <Flex direction="column" onClick={() => handleLightControl(3)}>
            <AppImg className="light-control-icon" src={ctrl.lightStatus === 3 ? icon_bright_pre : icon_bright_nor} />
            <span>明亮</span>
          </Flex>
        </Flex>
        <WhiteSpace />
        <Flex justify="around" className="light-control-section">
          <Flex direction="column" onClick={() => handleLightControl(4)}>
            <AppImg className="light-control-icon" src={ctrl.lightStatus === 4 ? icon_kge_pre : icon_kge_nor} />
            <span>K歌</span>
          </Flex>
          <Flex direction="column" onClick={() => handleLightControl(5)}>
            <AppImg className="light-control-icon" src={ctrl.lightStatus === 5 ? icon_auto_pre : icon_auto_nor} />
            <span>自动</span>
          </Flex>
          <Flex direction="column" onClick={() => handleLightControl(6)}>
            <AppImg className="light-control-icon" src={ctrl.lightStatus === 6 ? icon_close_pre : icon_close_nor} />
            <span>关闭</span>
          </Flex>
        </Flex> */}
        <WhiteSpace />
        <WhiteSpace />
        { ctrl.disabledControl && <div className="tips-layer">{intlMsg['tips.warning.control-auth']}</div> }
      </div>
    </Modal>
  )
}

export default observer(RemoteControlModal)
