import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './index.scss'
import FailTips from '../FailTips'

class LazyLoading extends Component {
  static propTypes = {
    errorContent: PropTypes.node,
    loading: PropTypes.bool,
    // 以下参数参考 `react-loadable` 的 opts.loading (LoadingComponent)
    error: PropTypes.any,
    retry: PropTypes.func.isRequired,
    timedOut: PropTypes.bool,
    pastDelay: PropTypes.bool,
  }

  static defaultProps = {
    loading: false,
  }

  render() {
    const { error, retry, errorContent, loading }: any = this.props

    if (error) {
      return (
        <FailTips text={errorContent} onClick={retry} />
      )
    } else if (loading) {
      return (
        <div className="spinner">
          <div className="double-bounce1" />
          <div className="double-bounce2" />
        </div>
      )
    }

    return React.Children.only(this.props.children)
  }
}

export default (props: any) => {
  return <LazyLoading {...props} loading />
}
