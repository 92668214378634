import React, { useEffect } from 'react'
import { Route } from 'react-router-dom';
import { getRoutes } from './';
import { RoutePropsType } from './PropsType';
import { getStore } from '@/store';

const RouteWrap: React.FC<RoutePropsType> = props => {
  const { component, title, auth, ...rest } = props
  const router = getRoutes()

  useEffect(() => {
    if (title) {
      document.title = title
    }
  }, [title])

  const RouteInner = ({ component: Component, ...rest }: any) => {
    if (auth) {
      const { userStore } = getStore()

      if (!userStore.userConnectStatus) {
        router.login.pushSelf()
      }
    }

    return <Component
      router={router}
      title={title}
      auth={auth}
      {...rest}
    />
  }

  return (
    <Route
      {...rest}
      render={props => <RouteInner {...props} component={component} />}
    />
  )
}

export default RouteWrap
