export default {
  "com.title.home": "RUMAH",
  "com.title.chosen": "DIPILIH",
  "com.title.more": "LEBIH BANYAK",
  "com.title.feedback": "MAKLUMBALAS", // new
  "com.title.set": "SEDIAKAN",
  "com.title.login": "Login", // new

  "com.user.connect": "SAMBUNGKAN",
  "com.user.disconnect": "TIDAK BERSAMBUNG",
  "com.user.room-list": "SENARAI BILIK PERIBADI",
  "com.user.table-list": "SENARAI JADUAL",
  "com.user.call-service": "PERKHIDMATAN PANGGILAN",
  "com.user.cancel-service": "BATALKAN PERKHIDMATAN",
  "com.user.logout": "退出登录",

  "com.song.name": "NAMA",
  "com.song.number": "NOMBOR",
  "com.song.language": "BAHASA",
  "com.song.song": "NAMA LAGU",
  "com.song.singer": "PENYANYI",
  "com.song.up-tone": "MENAIK",
  "com.song.down-tone": "DROP",
  "com.song.accompaniment": "BACKING",
  "com.song.original": "PENYANYI ASAL",
  "com.song.insert": "KEUTAMAAN",
  "com.song.delete": "PADAM",
  "com.song.add-collect": "TAMBAH KEGEMARAN",
  "com.song.select": "SONG",
  "com.song.ranking": "KEDUDUKAN",
  "com.song.new-song": "LAGU BARU",
  "com.song.category": "PENGKELASAN",
  "com.song.dance": "MUZIK TARIAN",
  "com.song.hot": "POPULER",
  "com.song.collect": "PENGUMPULAN",
  "com.song.history": "History",
  "com.song.all": "SEMUA",
  "com.song.play-control": "KAWALAN PENYIARAN",
  "com.song.next": "POTONG LAGU",
  "com.song.play": "BERMAIN",
  "com.song.pause": "TANGGUHKAN",
  "com.song.restart": "ULANG",
  "com.song.microphone": "MIKROFON",
  "com.song.music": "MUZIK",
  "com.song.tone": "NADA",
  "com.song.spell": "EJAAN JANE",
  "com.song.download": "MUAT TURUN",
  "com.song.downloading": "MEMUAT TURUN",
  "com.song.edit": "UBAH SUAI",
  "com.song.playing": "SEKARANG BERMAIN",
  "com.song.new-ytb": "YouTube",
  
  "page.login.login-in": "Daftar masuk",
  "page.login.hall": "Hall",
  "page.login.room": "Room",

  "page.chosen.order": "URUTAN",
  "page.chosen.myself": "JADUAL SAYA",

  "page.setting.collect-code": "KOD KEGEMARAN",
  "page.setting.version": "VERSI",

  "page.singer.cn-man": "LELAKI TANAH BESAR",
  "page.singer.cn-woman": "PEREMPUAN TANAH BESAR",
  "page.singer.hk-man": "LELAKI HONG KONG",
  "page.singer.hk-woman": "PEREMPUAN HONG KONG",
  "page.singer.tw-man": "LELAKI TAIWAN",
  "page.singer.tw-woman": "PEREMPUAN TAIWAN",
  "page.singer.oversea-man": "LELAKI LUAR NEGARA",
  "page.singer.oversea-woman": "PEREMPUAN LUAR NEGARA",
  "page.singer.other-man": "LELAKI LAIN",
  "page.singer.other-woman": "PEREMPUAN LAIN",
  "page.singer.band": "GABUNGAN",

  "tips.loading": "MEMUATKAN",

  "tips.success.handle": "OPERASI BERJAYA",
  "tips.success.collect": "KEJAYAAN KOLEKSI",
  "tips.success.clear-storage": "BERSIH BERJAYA,SILA MUAT SEMULA HALAMAN",
  "tips.success.connect": "BERSAMBUNG DENGAN JAYANYA",
  "tips.success.set": "SEDIAKAN DENGAN JAYANYA",

  "tips.warning.collect": "收藏已存在", // new
  "tips.warning.set-collect-code": "SILA TETAPKAN KOD KEGEMARAN ANDA TERLEBIH DAHULU",
  "tips.warning.control-auth": "HANYA MAINKAN LAGU-LAGU KEGEMARAN ANDA!",
  "tips.warning.input-password": "SILA MASUKKAN KATA LALUAN",
  "tips.warning.input-feedback": "SILA MASUKKAN MAKLUM BALAS",
  "tips.warning.input-connect-code": "SILA MASUKKAN KOD SAMBUNGAN 6 DIGIT",
  "tips.warning.table-closed": "JADUAL DITUTUP",
  "tips.warning.room-closed": "BILIK PERSENDIRIAN DITUTUP",
  "tips.warning.reconnect": "LOG MASUK GAGAL, SILA SAMBUNG SEMULA",
  "tips.warning.room-password-error": "包房密码错误", // new
  "tips.warning.link-again": "请先连接包房或桌台", // new
  "tips.warning.client-error": "客户端已经断开", // new
  "tips.warning.no-available": "No-available", // new
  "tips.no-data": "No Result!", // new

  "tips.error.network-fail": "RANGKAIAN SEDANG BERJALAN BURUK",
  "tips.error.page-lost": "HALAMAN HILANG",
  "tips.error.connect-code": "RALAT KOD SAMBUNGAN",
  "tips.error.token": "RALAT TOKEN",
  "tips.error.client-network": "PENGECUALIAN RANGKAIAN",
  "tips.error.logic": "KESILAPAN LOGIK",
  "tips.error.query-params": "RALAT PARAMETER",
  "tips.error.query": "MINTA RALAT",
  "tips.error.server": "KESILAPAN PELAYAN",

  "btn.retry": "CUBA SEMULA",
  "btn.confirm": "OK",
  "btn.cancel": "BATALKAN",
  "btn.submit": "HANTAR",
  "btn.back-home": "KEMBALI KE RUMAH",
  "btn.refresh": "Update", // new
  "btn.clear-data": "Clear Cache", // new

  "const.lang.jtzw": "BAHASA CINA RINGKAS",
  "const.lang.gy": "MANDARIN",
  "const.lang.ftzw": "CINA TRADISIONAL",
  "const.lang.yy": "KANTONIS",
  "const.lang.en": "BAHASA INGGERIS",
  "const.lang.ydnxyy": "BAHASA INDONESIA",
  "const.lang.mlxyy": "BAHASA MELAYU",
  "const.lang.mny": "MINNAN",
  "const.lang.ry": "JEPUN",
  "const.lang.hy": "KOREA",
  "const.lang.yny": "VIETNAM",
  "const.lang.jpzy": "KEMBOJA",
  "const.lang.fy": "PERANCIS",
  "const.lang.ty": "THAI",
  "const.lang.ydy": "HINDI",
  "const.lang.flby": "BAHASA FILIPINA",
  "const.lang.xbyy": "SEPANYOL",
  "const.lang.ey": "RUSIA",
  "const.lang.mdy": "BURMA",
  "const.lang.tmr": "TAMIL",
  "const.lang.yday": "HINDI",
  "const.lang.cz": "CHAOZHOU",
  "const.lang.other": "BAHASA LAIN",

  "const.type.img_fenlei01": "CHORUS",
  "const.type.img_fenlei02": "LAGU REVOLUSI",
  "const.type.img_fenlei03": "LAGU KANAK-KANAK",
  "const.type.img_fenlei04": "NOSTALGIA",
  "const.type.img_fenlei05": "PERAYAAN",
  "const.type.img_fenlei06": "OPERA KANTON",
  "const.type.img_fenlei07": "PEKING OPERA",
  "const.type.img_fenlei08": "LAGU ULANG TAHUN",
  "const.type.img_fenlei09": "OPERA YUE",
  "const.type.img_fenlei10": "OPERA HUANGMEI",
  "const.type.img_fenlei11": "DRAMA LAIN",
  "const.type.img_fenlei12": "CHAO OPERA",
  "const.type.img_fenlei13": "MUZIK RINGAN",
  "const.type.img_fenlei14": "SHAAXI QINQIANG",
  "const.type.img_fenlei15": "TIMUR LAUT DUOZHUAN",
  "const.type.img_fenlei16": "YU OPERA",
  "const.type.img_fenlei17": "LAGU TIBET",
  "const.type.img_fenlei18": "LAGU XINJIANG",
  "const.type.img_fenlei19": "HIFI MUSIC",

  "const.type.img_dance02": "SKEWER",
  "const.type.img_dance03": "DISCO",
  "const.type.img_dance04": "CHA CHA",
  "const.type.img_dance05": "PRUSSIA",
  "const.type.img_dance06": "TANGO",
  "const.type.img_dance07": "WALTZ",
  "const.type.img_dance08": "JITBA",
  "const.type.img_dance09": "GONCANGKAN PERLAHAN-LAHAN",
  "const.type.img_dance10": "TARIAN SOCIAL",
  "const.type.img_dance11": "KELAB MALAM",
  "const.type.img_dance12": "SUARA PSYCHEDELIC",
  "const.type.img_dance13": "KECERGASAN TANPA HAD",
  "const.type.img_dance14": "ELEKTRONIK SEGAR",
  "const.type.img_dance15": "MADDEN CHINESE",

  "const.type.img_paihang": "KEDUDUKAN KESELURUHAN",
  "const.type.img_chinese": "CINA TERATUS",
  "const.type.img_yueyu": "KANTONIS TERATAS",
  "const.type.img_minnanyu": "MINNAN TERATAS",
  "const.type.img_english": "BAHASA INGGERIS TERATAS",
  "const.type.img_ktv": "KTV GOLDEN SONGS",
  "const.type.img_hot_song": "SENARAI LAGU PANAS",
  "const.type.img_rock": "SENARAI ROCK",

  "const.type.hot_zgyyggp": "IDOL HITS",
  "const.type.hot_jdylc": "EVERLASTING CLASSIC",
  "const.type.hot_wxgyj": "UNLIMITED SONG SEASON",
  "const.type.hot_zgxsd": "LET'S SING KID'S",
  "const.type.hot_mrzz": "THE COMING ONE",
  "const.type.hot_zgxsc": "TEH RAP OF CHINA",
  "const.type.hot_ayzc": "PHANTACITY",
  "const.type.hot_zydwm": "BEST US",
  "const.type.hot_srrx": "SUPER VOCAL",
  "const.type.hot_gs": "I AM A SINGER",
  "const.type.hot_zghsy": "SING! CHINA",
  "const.type.hot_wwgk": "MAD OF MUSIC",
  "const.type.hot_zgzqy": "TEH X FACTOR",
  "const.type.hot_zgmzs": "CHINESE IDOL",
  "const.type.hot_zmhs": "DUETS",
  "const.type.hot_qnxz": "CELEBRITY BATTLE",
  "const.type.hot_zghgq": "SING MY SONG ",
  "const.type.hot_zgzx": "CHINA STAR",
  "const.type.hot_wxhnc": "COME SING WITH ME",
  "const.type.hot_kjgw": "KIGN CROSS",
  "const.type.hot_ycgs": "TELENTED SINGER",
  "const.type.hot_gsyx": "HEROS OF EARTH",
  "const.type.hot_zgxgs": "SING! CHINA",
  "const.type.hot_mmgw": "MASKED KING",
  "const.type.hot_tlzz": "TEH SOUND OF WARS",
  "const.type.hot_mmcj": "MASK SINGER",
  "const.type.hot_mxdsy": "SOUND OF MY DREAM",
  "const.type.hot_wlyyh": "THE RECYCLING CONCERT",
  "const.type.hot_lhlwdg": "WOW!MY SONG",
  "const.type.hot_jql": "GOLDEN MELODY",
  "const.type.hot_zgyxh": "TEH RAP OF CHINA",
}
