import React, { useEffect, useState } from 'react';
import RemoteControl from '@/components/RemoteControl';
import RoomListModal from '../RoomListModal';
import TableListModal from '../TableListModal';
import ChooseSong from '../ChooseSong';
import { eventEmitter, eventEmitter_KEYS } from '@/utils/eventEmitter';
import { getRoutes, history } from '@/router';

const RootView: React.FC<any> = (props) => {
  const { children } = props
  const router = getRoutes()
  const [ isShowControl, setIsShowControl ] = useState(false)

  useEffect(() => {
    if (history.location.pathname !== router.login.attrs.path) {
      setIsShowControl(true)
    } else {
      setIsShowControl(false)
    }
  }, [])

  useEffect(() => {
    eventEmitter.addListener(eventEmitter_KEYS.connect, () => {
      setIsShowControl(true)
    })
    eventEmitter.addListener(eventEmitter_KEYS.disconnect, () => {
      setIsShowControl(false)
    })

    return () => {
      eventEmitter.removeAllListeners(eventEmitter_KEYS.connect)
      eventEmitter.removeAllListeners(eventEmitter_KEYS.disconnect)
    }
  }, [isShowControl])

  return (
    <div className="root-view">
      {
        children
      }
      {
         isShowControl && <RemoteControl/>
      }
      <RoomListModal />
      <TableListModal />
      <ChooseSong />
    </div>
  );
};

export default RootView;
