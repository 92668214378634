import React, { useEffect } from 'react'
import { Modal, Button, Flex, WhiteSpace, WingBlank } from 'antd-mobile'
import { ToastSuccess, ToastFail } from '../../utils/utils'

import btn_drop from '../../assets/images/btn_drop.png'
import btn_plus from '../../assets/images/btn_plus.png'

import icon_banchang from '@/assets/images/icon_banchang.png'
import icon_yuanchang_pre1 from '@/assets/images/icon_yuanchang_pre1.png'

import './index.scss'
import Player from '../Player'

import { orderSong, favariteAdd, orderSongEdit } from '@/api/api'
import { useLocalStore, observer } from 'mobx-react-lite'
import AppImg from '../AppImg'
import { langTypes, videoAssetUrlPrefix } from '@/constants'
import { getColletionCode, showColletionCodeModal } from '@/utils/userHelper'
import { useStore } from '@/store'
import { useFormatMessage } from '@/locales'
import { eventEmitter, eventEmitter_KEYS } from '@/utils/eventEmitter'

interface ChooseSongProps {

}

const ChooseSong: React.FC<ChooseSongProps> = props => {
  const { userStore, songStore } = useStore()
  const source = songStore.currentChooseSongData

  const intlMsg = useFormatMessage()

  const song = useLocalStore(() => ({
    songNumber: '',
    mode: 'select',
    voice: 'accomp',
    tone: 0
  }))

  useEffect(() => {
    song.songNumber = source.songNumber
    if (!songStore.isChooseSongEdit) return
    song.voice = source.voice
    song.tone = Number(source.tone) || 0
  }, [songStore.chooseSongModalVisible])

  const onClose = () => {
    songStore.updateChooseSongModalVisible(false)
  }

  const handleChooseSong = async (mode: string) => {
    await orderSong({
      ...song,
      [source.linked && 'linked']: source.linked, // 如果 linked 有值，则是长按给包房点歌，需要在请求中覆盖包房的 linked 值
      mode
    })

    ToastSuccess(intlMsg['tips.success.handle'])
    onClose()
  }

  const handleEditSong = async (mode: string, isClose = true) => {
    await orderSongEdit({
      ...song,
      timeStamp: source.timeStamp,
      mode
    })

    ToastSuccess(intlMsg['tips.success.handle'])

    if (isClose) {
      onClose()
      eventEmitter.emit(eventEmitter_KEYS.refreshSongList)
    }
  }

  const handleCollectSong = async () => {
    const code = await getColletionCode()

    if (!code) {
      showColletionCodeModal(intlMsg['tips.warning.set-collect-code'])
      return
    }

    const { result } = await favariteAdd({
      favariteId: code,
      songNumber: source.songNumber,
      songName: source.songName,
      singerName: source.singerName,
      lang: source.lang,
    })

    if (result === 'success') {
      ToastSuccess(intlMsg['tips.success.collect'])
      onClose()
    } else {
      ToastFail(intlMsg['tips.warning.collect'])
    }
  }

  const handleChangeVoice = () => {
    song.voice = song.voice === 'accomp' ? 'original' : 'accomp'
  }

  const handleChangeTone = (type: any) => {
    if (type === 'up' && song.tone < 5) {
      song.tone++
    }

    if (type === 'down' && song.tone > -5) {
      song.tone--
    }
  }

  return (
    <Modal closable popup visible={songStore.chooseSongModalVisible} onClose={onClose} animationType="slide-up">
      <div className="choose-song-wrap">
        <div className="song-info">
          <div>
            <p>
              <span>{intlMsg['com.song.name']}:</span>
              <span>{source.roomName || userStore.connectName}</span>
            </p>
          </div>
          <div>
            <p>
              <span>{intlMsg['com.song.number']}:</span>
              <span>{source.songNumber}</span>
            </p>
            <p>
              <span>{intlMsg['com.song.language']}:</span>
              <span>{langTypes[source.lang] || langTypes[0]}</span>
            </p>
          </div>
          <div>
            <p>
              <span>{intlMsg['com.song.song']}:</span>
              <span>{source.songName}</span>
            </p>
            <p>
              <span>{intlMsg['com.song.singer']}:</span>
              <span>{source.singerName}</span>
            </p>
          </div>
        </div>
        {
          !songStore.isChooseSongEdit && <Player source={`${videoAssetUrlPrefix}${source.vurl}`} style={{ width: '100%' }} />
        }
        <WhiteSpace />
        <WhiteSpace />
        <Flex justify="between">
          <Flex>
            <Flex direction="column">
              <AppImg className="control-icon" src={btn_plus} onClick={() => handleChangeTone('up')} />
              <span>{intlMsg['com.song.up-tone']}</span>
            </Flex>
            <Flex className="tone-number">{song.tone > 0 ? `+${song.tone}` : song.tone}</Flex>
            <Flex direction="column">
              <AppImg className="control-icon" src={btn_drop} onClick={() => handleChangeTone('down')} />
              <span>{intlMsg['com.song.down-tone']}</span>
            </Flex>
          </Flex>
          <Flex direction="column">
            <AppImg className="control-icon" src={song.voice === 'accomp' ? icon_banchang: icon_yuanchang_pre1} onClick={handleChangeVoice} />
            <span>{song.voice === 'accomp' ? intlMsg['com.song.accompaniment'] : intlMsg['com.song.original']}</span>
          </Flex>
        </Flex>
        <WhiteSpace />
        <WhiteSpace />
        {
          songStore.isChooseSongEdit ? (
            <Flex>
              <Flex.Item>
                <Button size="small" onClick={() => handleEditSong('insert')}>{intlMsg['com.song.insert']}</Button>
              </Flex.Item>
              <WingBlank size="md" />
              <Flex.Item>
                <Button size="small" type="warning" onClick={() => handleEditSong('delete')}>
                  {intlMsg['com.song.delete']}
                </Button>
              </Flex.Item>
              <WingBlank size="md" />
              <Flex.Item>
                <Button size="small" type="primary" onClick={() => handleEditSong('edit')}>
                  {intlMsg['btn.confirm']}
                </Button>
              </Flex.Item>
            </Flex>
          ) : (
            <Flex>
              {
                !songStore.hideAddCollect && (
                  <>
                    <Flex.Item>
                      <Button size="small" onClick={handleCollectSong}>{intlMsg['com.song.add-collect']}</Button>
                    </Flex.Item>
                    <WingBlank size="md" />
                  </>
                )
              }
              <Flex.Item>
                <Button size="small" onClick={() => handleChooseSong('insert')}>{intlMsg['com.song.insert']}</Button>
              </Flex.Item>
              <WingBlank size="md" />
              <Flex.Item>
                <Button size="small" type="warning" onClick={() => handleChooseSong('select')}>
                  {intlMsg['com.song.select']}
                </Button>
              </Flex.Item>
            </Flex>
          )
        }
      </div>
    </Modal>
  )
}

export default observer(ChooseSong)
