import localforage from 'localforage'

const KEYS = {
  userInfo: 'userInfo',
  SN: 'SN',
  loginRoomModalTime: 'loginRoomModalTime',
  colletionCode: 'colletionCode',
  language: 'language',
}

export default {
  KEYS,
  ...localforage,
}

export function localStorageSetItem(key: string, value: any) {
  return localStorage.setItem(key, value)
}

export function localStorageGetItem(key: string) {
  return localStorage.getItem(key)
}
