import { Modal } from 'antd-mobile'
import { snRequest, phoneConnect, roomEnterPasswd } from '@/api/api';
import storage from '@/utils/storage';
import { getParameterByName, ToastSuccess } from '@/utils/utils';
import { getStore } from '@/store';
import { getFormatMessage } from '@/locales';
import { eventEmitter, eventEmitter_KEYS } from './eventEmitter';

export async function getUserName() {
  const data: any = await storage.getItem(storage.KEYS.userInfo)
  return data && data.userName
}

export async function getMachineName() {
  const data: any = await storage.getItem(storage.KEYS.userInfo)
  return data && data.machineName
}

export async function getLinked() {
  const data: any = await storage.getItem(storage.KEYS.userInfo)
  return data && data.linked
}

export async function getType() {
  const data: any = await storage.getItem(storage.KEYS.userInfo)
  return data && data.type
}

export async function getName() {
  const data: any = await storage.getItem(storage.KEYS.userInfo)
  return data && data.name
}

export async function getToken() {
  const data: any = await storage.getItem(storage.KEYS.userInfo)
  return data && data.token
}

export async function getSN(): Promise<string> {
  return await storage.getItem(storage.KEYS.SN)
}

export async function getColletionCode(): Promise<string> {
  return await storage.getItem(storage.KEYS.colletionCode)
}

export async function getLoginRoomModalTime(): Promise<number> {
  return await storage.getItem(storage.KEYS.loginRoomModalTime)
}

export async function initUserStatus() {
  const { userStore } = getStore()

  const token = await getToken()
  const code = await getColletionCode()
  const sn = await getSN()

  userStore.updateUserConnectStatus(!!token)
  userStore.colletionCode = code
  userStore.sn = sn
}

export async function initSN() {
  if (await getSN()) return

  const { userStore } = getStore()

  const data: any = await snRequest()
  await storage.setItem(storage.KEYS.SN, data.result)
  userStore.sn = data.result
}

export async function userConnect(linkCode?: string, obj?: object) {
  let params = null
  const intlMsg = getFormatMessage()

  await initSN()

  if (linkCode) {
    const { msgType, ...data } = await phoneConnect({
      linkCode
    })

    params = data
  } else {
    params = obj
  }

  const { userStore } = getStore()

  storage.setItem(storage.KEYS.userInfo, params).then(() => {
    ToastSuccess(intlMsg['tips.success.connect'])
    userStore.updateUserConnectStatus()
    eventEmitter.emit(eventEmitter_KEYS.connect)
  })
}

export async function autoUserConnect() {
  const linkCode = getParameterByName('linkCode')
  const { userStore, headerStore } = getStore()

  if (linkCode) {
    userConnect(linkCode)
  } else if (!userStore.userConnectStatus) {
    headerStore.updateUserDrawerVisible()
  }
}

export function showUserConnectModal({ cancelBtn = true } = {}) {
  const intlMsg = getFormatMessage()
  const callbackOrActions: any = [
    {
      text: intlMsg['btn.confirm'],
      onPress: (value: any) =>
        new Promise(async (resolve, reject) => {
          if (!value.trim()) return reject()
          await userConnect(value)
          return resolve()
        }),
    }
  ]

  cancelBtn && callbackOrActions.unshift({ text: intlMsg['btn.cancel'] })

  Modal.prompt(intlMsg['com.user.connect'], intlMsg['tips.warning.input-connect-code'], callbackOrActions)
}

export async function showColletionCodeModal(tips = '') {
  const { userStore } = getStore()
  const intlMsg = getFormatMessage()
  const code = await getColletionCode()
  Modal.prompt(`${intlMsg['com.title.set']}${intlMsg['page.setting.collect-code']}`, tips, [
    { text: intlMsg['btn.cancel'] },
    {
      text: intlMsg['btn.confirm'],
      onPress: (val: string) => {
        storage.setItem(storage.KEYS.colletionCode, val.trim()).then((code) => {
          ToastSuccess(intlMsg['tips.success.set'])
          eventEmitter.emit(eventEmitter_KEYS.updateColletionCode, code)
          userStore.colletionCode = code
        })
      }
    }
  ], 'default', code)
}

export async function updateLoginRoomModalStatus(key: string) {
  await roomEnterPasswd({
    key
  })
  storage.setItem(storage.KEYS.loginRoomModalTime, Date.now())
}

export async function userDisconnect() {
  const { headerStore, userStore, songStore } = getStore()
  storage.removeItem(storage.KEYS.userInfo)
  headerStore.updateUserDrawerVisible()
  userStore.updateUserConnectStatus(false)
  headerStore.updateTableListModalVisible(false)
  headerStore.updateRoomListModalVisible(false)
  songStore.updateChooseSongModalVisible(false)
  eventEmitter.emit(eventEmitter_KEYS.disconnect)
}
