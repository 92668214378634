import { observable, action, runInAction } from 'mobx'
import BaseStore from './baseStore'
import { playModeStatus } from '@/api/api'

const initPlayerSongData = {
  songname: '',
  singername: '',
  dration: 0,
  played: 0
}

let timer: any = null

class SongStore extends BaseStore {
  @observable
  isPlaying = false

  @observable
  currentPlayerSongData: any = initPlayerSongData

  @observable
  currentChooseSongData: any = {}

  @observable
  chooseSongModalVisible = false

  @observable
  isChooseSongEdit = false

  @observable
  hideAddCollect = false

  @action
  updateChooseSongModalVisible(val: boolean = true) {
    this.chooseSongModalVisible = val

    // if (!val) {
    //   this.currentChooseSongData = {}
    // }
  }

  async getPlayModeStatus() {
    const data: any = await playModeStatus()

    // if (data.played === data.dration) {
    //   clearInterval(timer)
    //   this.isPlaying = false
    //   this.currentPlayerSongData = initPlayerSongData
    //   return
    // }

    runInAction(() => {
      this.currentPlayerSongData = data
    })
  }

  @action
  startPlaySong() {
    this.getPlayModeStatus()
    this.isPlaying = true

    if (timer) {
      this.clearPlayStatus()
    }

    timer = setInterval(this.getPlayModeStatus.bind(this), 6000)
  }

  @action
  clearPlayStatus() {
    clearInterval(timer)
  }
}

export default SongStore
