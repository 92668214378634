import { getFormatMessage } from '@/locales'

const intlMsg = getFormatMessage()

export const routes = [
  {
    loader: () => import('../../pages/Demo'),
    path: '/demo',
    name: 'demo',
    title: 'Demo'
  },
  {
    loader: () => import('../../pages/Login'),
    path: '/login',
    name: 'login',
    title: intlMsg["com.title.login"]
  },
  {
    loader: () => import('../../pages/Category'),
    path: '/category',
    name: 'category',
    title: intlMsg["com.song.category"],
    auth: true,
  },
  {
    loader: () => import('../../pages/Chosen'),
    path: '/chosen',
    name: 'chosen',
    title: intlMsg["com.title.chosen"],
    auth: true,
  },
  {
    loader: () => import('../../pages/Dance'),
    path: '/dance',
    name: 'dance',
    title: intlMsg["com.song.dance"],
    auth: true,
  },
  {
    loader: () => import('../../pages/Home'),
    path: '/',
    name: 'home',
    title: intlMsg["com.title.home"],
    auth: true,
  },
  {
    loader: () => import('../../pages/Hot'),
    path: '/hot',
    name: 'hot',
    title: intlMsg["com.song.hot"],
    auth: true,
  },
  {
    loader: () => import('../../pages/Rank'),
    path: '/rank',
    name: 'rank',
    title: intlMsg["com.song.ranking"],
    auth: true,
  },
  {
    loader: () => import('../../pages/Setting'),
    path: '/setting',
    name: 'setting',
    title: intlMsg["com.title.more"],
    auth: true,
  },
  {
    loader: () => import('../../pages/Song'),
    path: '/song',
    name: 'song',
    title: intlMsg["com.song.song"],
    auth: true,
  },
  {
    loader: () => import('../../pages/Singer'),
    path: '/singer',
    name: 'singer',
    title: intlMsg["com.song.singer"],
    auth: true,
  },
  {
    loader: () => import('../../pages/Singer/Detail'),
    path: '/singer/detail',
    name: 'singerDetail',
    title: intlMsg["com.song.singer"],
    auth: true,
  },
  {
    loader: () => import('../../pages/Singer/List'),
    path: '/singer/list',
    name: 'singerList',
    title: intlMsg["com.song.singer"],
    auth: true,
  },
  {
    loader: () => import('../../pages/Feedback'),
    path: '/feedback',
    name: 'feedback',
    title: intlMsg["com.title.feedback"],
    auth: true,
  }
]
