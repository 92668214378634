import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import './index.scss'
import { ActivityIndicator, List } from 'antd-mobile';
import FailTips from '../FailTips';
import { generateCancelToken, isCancel } from '@/api/request';
import { cloneDeep } from 'lodash';
import { useFormatMessage } from '@/locales';

export interface AppListProps extends GT.ComponentProps {
  request: Function;
  row?: any;
  type?: 'list' | 'custom';
  reFetch?: any;
  requestParams?: any;
  updateIndex?: any;
}

let cancelToken: any

const AppList: React.FC<AppListProps> = (props) => {
  const { row: Row, request, type = 'list', reFetch = "", requestParams = "", updateIndex, className, style } = props
  const [source, setSource] = useState([])
  const [loading, setLoading] = useState(true)
  const [fetchError, setFetchError] = useState(false)

  const intlMsg = useFormatMessage()

  useEffect(() => {

    fetchData()

    return () => {
      cancelToken && cancelToken.cancel()
    }

  }, [reFetch, requestParams])

  useEffect(() => {
    if (updateIndex >= 0) {
      const list: any = cloneDeep<any>(source)
      list[updateIndex].cloud = 2
      setSource(list)
    }
  }, [updateIndex])

  const fetchData = async () => {
    cancelToken && cancelToken.cancel()
    cancelToken = generateCancelToken()

    setFetchError(false)
    setLoading(true)

    const data = await request(requestParams, {
      cancelToken: cancelToken.token
    }).catch((e: any) => {
      console.log(e);
      if (isCancel(e)) return
      setFetchError(true)
    }).finally(() => {
      setLoading(false)
    })

    if (data) {
      setSource(data)
    }
  }

  const ItemList = () => <>{ source.map((item: any, index) => (<Row source={item} index={index} key={index} />)) }</>

  return (
    <div className="app-list-wrap">
      {
        loading && <ActivityIndicator text={intlMsg['tips.loading']} />
      }
      {
        fetchError && <FailTips buttonSize="small" onClick={fetchData} />
      }
      {
        !loading && !fetchError && source.length === 0 && <p className="no-data-tips">{intlMsg['tips.no-data']}</p>
      }
      <div className={classnames('app-list-inner', className)} style={{...style, visibility: loading ? 'hidden': 'visible'}}>
        {
          type === 'list' ? (
            <List>
              <ItemList />
            </List>
          ) : (
            <>
              <ItemList />
            </>
          )
        }
      </div>
    </div>
  );
};

export default AppList;
