import React from 'react'
import { getRoutes } from '@/router'
import FailTips from '@/components/FailTips'
import { useFormatMessage } from '@/locales'

const router = getRoutes()

export default () => {
  const intlMsg = useFormatMessage()

  return (
    <FailTips text={intlMsg['tips.error.page-lost']} buttonText={intlMsg['btn.back-home']} onClick={() => router.home.pushSelf()} />
  )
}
